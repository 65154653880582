import {
    Auth,
    EmailAuthProvider,
    getAuth,
    indexedDBLocalPersistence,
    reauthenticateWithCredential,
    sendPasswordResetEmail,
    setPersistence,
    signInWithEmailAndPassword,
    updatePassword,
    User,
    UserCredential,
} from "firebase/auth";
import {firebaseApp} from "../Firebase/firebaseApp";
import {SENTRY} from "../SENTRY";
import {resolveOTP} from "../Firebase/resolveOTP";
import {deleteToken, getMessaging} from "firebase/messaging"

export class tokenManagerClass extends EventTarget {
    gAuth: Auth

    get [Symbol.toStringTag]() {return this.constructor.name}
    constructor() {
        super()
        this.gAuth = getAuth(firebaseApp)
        // Update account persistence
        setPersistence(this.gAuth, indexedDBLocalPersistence)
            .then(() => {
                console.log("Session persistence configured")
            })
            .catch(e => {
                console.log(e)
            })

        this.gAuth.onAuthStateChanged((user) => {
            if (!user) {
                this.dispatchEvent(new CustomEvent("firebase_logout"))
                SENTRY.setUser(null)
            }
            else {
                SENTRY.setUser({
                    email: user.email || "anonymous@farmmedix.com",
                    username: user.displayName || "anonymous",
                    id: user.uid
                })
            }
        })
    }

    signInEmail(email: string, password: string) {
        return new Promise((resolve, reject) => {
            let creds: UserCredential
            signInWithEmailAndPassword(this.gAuth, email, password)
                .then((_creds) => {
                    creds = _creds
                    return setPersistence(this.gAuth, indexedDBLocalPersistence)
                })
                .then(() => {
                    resolve(creds)
                })
                .catch((e) => {
                    console.log(e)
                    if (e.code == 'auth/multi-factor-auth-required') {
                        //resolve otp
                        resolveOTP(e, this.gAuth).then(() => {
                            return setPersistence(this.gAuth, indexedDBLocalPersistence)
                        })
                            .then(() => {
                                resolve('true')
                            })
                            .catch(e => {
                                reject(e.code)
                            })
                    }
                    else if (e.code == 'auth/wrong-password') {
                        // Handle other errors such as wrong password.
                        reject(e.code)
                    }

                })
        })
    }

    async signOut() {
        // Remove push token
        try {await deleteToken(getMessaging(firebaseApp))} catch(e) {console.error(e)}
        return await this.gAuth.signOut()
    }

    getToken() {
        return this.gAuth.currentUser?.getIdToken()
    }

    async changePassword(oldPassword: string, newPassword: string) {
        let user = await this.gAuth.currentUser
        try {
            if (!user) throw new Error("No logged-in user")
            else if (!user.email) throw new Error("User does not have a given email address")
            console.log(user)

            await reauthenticateWithCredential(
                user,
                EmailAuthProvider.credential(
                    user.email,
                    oldPassword
                )
            )
            await updatePassword(user, newPassword)
        }
        catch (e) {
            if ((e as {code?: string}).code == 'auth/multi-factor-auth-required') {
                //start otp check
                await resolveOTP(e, this.gAuth)
                await updatePassword(user as User, newPassword)
            }
            else {
                throw e
            }
        }
    }

    forgotPassword(email: string) {
        return sendPasswordResetEmail(this.gAuth, email)
    }
}

export const tokenManager = new tokenManagerClass()
