import {ManagerBaseClass} from "./ManagerBaseClass";
import {Report} from "../DatabaseData/Tests/report";
import {Database} from "./DBClass";
import {TestData} from "../../models/DatabaseDataTypes";
import {TABLES} from "../../models/TABLES";
import {WORKER} from "../ServiceWorker/domServiceWorkerUtilities";
import {QUEUE_TYPES, SyncStatusEventsService} from "./events/syncStatusEventsService";
import {NotificationErr} from "../../components/notificationErr";

export class ReportManagerClass extends ManagerBaseClass {
    get [Symbol.toStringTag]() {
        return this.constructor.name
    }

    public syncInProgress: boolean
    readonly progress_bar: HTMLElement

    constructor() {
        super();
        // this.DBClass = DBClass

        this.syncInProgress = false
        let progress_bar = document.getElementById("snapshot_dropdown")
        if (progress_bar) progress_bar = <HTMLElement>progress_bar.getElementsByClassName("progress_bar")[0] as HTMLDivElement
        this.progress_bar = progress_bar as HTMLDivElement

        // Setup listeners
        SyncStatusEventsService.on("onQueueStart", () => {
            this.showProgressBar()
        }, {queue: QUEUE_TYPES.Report})
        SyncStatusEventsService.on("onQueueEnd", () => {
            this.hideProgressBar()
        }, {queue: QUEUE_TYPES.Report})
        SyncStatusEventsService.on("onQueueUpdate", (event) => {
            if (!this.progress_bar) return
            this.showProgressBar()

            if (event.details[0].total === Infinity) {
                if (this.progress_bar) this.progress_bar.style.width = "20px"
            }
            else {
                let decimal = (event.details[0].complete / event.details[0].total)
                if (this.progress_bar) this.progress_bar.style.width = `calc(${Math.round(decimal * 100)}% + 20px)`
            }

        }, {queue: QUEUE_TYPES.Report})
        SyncStatusEventsService.on("onQueueError", (event) => {
            if (event.details[0].error instanceof Error) {
                NotificationErr.thisTab("<i class=\"fa-regular fa-rotate-exclamation\"></i>",
                    "An error occured while syncing your data. The error has been automatically reported. " + event.details[0].error.message
                )
            }
            else if (typeof event.details[0].error === "string") {
                NotificationErr.thisTab("<i class=\"fa-regular fa-rotate-exclamation\"></i>",
                    "An error occured while syncing your data. The error has been automatically reported. " + event.details[0].error
                )
            }
            else {
                NotificationErr.thisTab("<i class=\"fa-regular fa-rotate-exclamation\"></i>",
                    "An error occured while syncing your data. The error has been automatically reported. Unknown error"
                )
            }
        }, {queue: QUEUE_TYPES.Report})
    }

    private showProgressBar() {
        if (!this.progress_bar) return
        if (this.progress_bar.style.opacity === "1") return // Immediately exit if it is already showing
        this.progress_bar.style.opacity = "1"
        this.progress_bar.style.marginTop = "10px"
        this.progress_bar.style.width = "10px"
    }

    private hideProgressBar() {
        if (!this.progress_bar) return
        this.progress_bar.style.width = "calc(100% + 20px)"
        this.progress_bar.style.opacity = "0"
        this.progress_bar.style.marginTop = ""
    }

    sync() {
        WORKER?.messageSW({
            action: "SYNC"
        })
    }


    async getAll(filter: (r: TestData) => boolean = () => true): Promise<Report[]> {
        return (await Database.search(TABLES.tests, filter)).map(record => {
            return new Report(record)
        })
    }

    async get(report_id: string) {
        return new Report((await Database.get(TABLES.tests, report_id)))
    }

    async handleSocketMessage(msg: any) {
        // let account = await AccountManager.get()
        // console.log(msg)
        // if (msg.action === "update") {
        //     // Download/Update a Report
        //     let headers = new Headers()
        //     headers.set("Content-Type", "application/json")
        //     headers.set("Authorization", "Bearer " + await tokenManager.token)
        //
        //     // Detect whether to do a partial or full update
        //     let report = await this.get(msg.reportId)
        //     if (msg.data && report) {
        //         // Socket message has partial data, and the Report is already known
        //         msg.data.uuid = msg.reportId
        //         let or_data = (await report.generateUploadData(true)).data
        //         for (let key of Object.keys(msg.data)) or_data[key] = msg.data[key]
        //         let data = await convertValuesToReport(or_data, "none")
        //         data.recordId = report.recordId
        //         data.uuid = report.data.uuid
        //         data.status = report.data.status
        //         data.farm_id = report.data.farm_id
        //         data.name = report.data.name
        //         data.contractorNote = report.data.contractorNote
        //         await this._saveReportData(data, [], msg.reportId)
        //         // this.dispatchEvent(new CustomEvent("report_partial_downloaded", {detail: {uuid: msg.reportId}}))
        //     }
        //     else {
        //         // Either the messages does not have partial data, or the Report is not known
        //         await this._downloadReport(msg.reportId, headers, this)
        //     }
        // }
    }
}

export const ReportManager = new ReportManagerClass()

