import {Header} from "./header";
import {Database} from "../../services/DBClass";
import {HeaderData, SectionData} from "../../../models/DatabaseDataTypes";
import {DatabaseBatchOperation} from "../../databaseBatchOperation";
import {TABLES} from "../../../models/TABLES";

export class Section {
    sectionId: string
    data: SectionData

    static fromArray(data: SectionData[]) {
        return data.map(i => new this(i.uuid, i))
    }

    static from(data: SectionData) {return new this(data.uuid, data)}
    get [Symbol.toStringTag]() {return this.constructor.name}
    constructor(sectionId: string, data: SectionData) {
        this.sectionId = sectionId
        this.data = data

        // this.manager.addEventListener("section_id_changed", (e) => {
        //     // @ts-ignore
        //     if (e.detail.old === this.sectionId) this.sectionId = e.detail.new
        // })
    }

    get() {
        return Database.get(TABLES.sections, this.sectionId)
            .then(e => {
                this.data = e
                return this
            })
    }

    async headers(): Promise<Header[]> {
        let headers = await this.getRawHeaderData()
        return headers.map(_header => {
            return new Header(_header.uuid, _header)
        })
    }

    async getRawHeaderData(): Promise<HeaderData[]> {
        let headers = await Database.getByIndex(TABLES.headers, "sectionId", IDBKeyRange.only(this.sectionId))
        headers = headers.sort((a, b) => {
            if (a.pos > b.pos) return 1
            else if (a.pos === b.pos) return 0
            else return -1
        })
        return headers
    }

    async delete(batch?: DatabaseBatchOperation) {
        let commit_batch = !batch
        if (!batch) batch = Database.batchOperation()
        // If no batch operation was passed in, create a batch operation and commit it at the end

        for (let header of await this.headers()) await header.delete(batch)

        batch.delete(TABLES.sections, this.sectionId)
        if (commit_batch) await batch.commit()
    }
}