import * as Sentry from "@sentry/browser";
import {wait} from "../utils/utilities";
import {AccountManager} from "../utils/services/accountManager";
import {FarmManager} from "../utils/services/farmManager";

export class AccountMenu {
    protected div: HTMLElement
    protected optionsDiv: HTMLElement
    protected greyArea: HTMLElement
    protected appInfo = new AppInfoIndicator()

    constructor() {
        this.div = document.createElement("div")
        this.div.classList.add("user_options_wrapper")
        this.optionsDiv = document.createElement("div")

        let my_account_btn = document.createElement("div")
        my_account_btn.onclick = () => {
            window.location.href = '/account'
        }
        my_account_btn.innerHTML = "<i class=\"fa-solid fa-circle-user\"></i> My Account"

        let logout_btn = document.createElement("div")
        logout_btn.onclick = () => {
            AccountManager.logout()
        }
        logout_btn.innerHTML = "<i class=\"fa-solid fa-arrow-right-from-bracket\"></i> Logout"

        let report_btn = document.createElement("div")
        report_btn.onclick = () => {
            this.openIssueReport()
        }
        report_btn.innerHTML = "<i class=\"fa-solid fa-bug\"></i> Report an issue"

        let sync_btn = document.createElement("div")
        sync_btn.onclick = () => {
            this.close()
            FarmManager.sync()
        }
        sync_btn.innerHTML = "<i class=\"fa-solid fa-rotate\"></i> Sync now"

        this.optionsDiv.appendChild(my_account_btn)
        this.optionsDiv.appendChild(sync_btn)
        this.optionsDiv.appendChild(report_btn)
        this.optionsDiv.appendChild(document.createElement("hr"))
        this.optionsDiv.appendChild(logout_btn)

        this.optionsDiv.classList.add("user_options_div")
        this.greyArea = document.createElement("div")
        this.div.appendChild(this.greyArea)
        this.div.appendChild(this.optionsDiv)
        this.optionsDiv.appendChild(this.appInfo.div)
        // this.appInfo.div.style.position = "absolute"
        this.appInfo.div.style.bottom = "0"
        this.appInfo.render()
        document.body.appendChild(this.div)
        this.greyArea.onclick = (e) => {
            this.close()
        }
    }

    openIssueReport() {
        const eventId = Sentry.lastEventId() || Sentry.captureMessage("User Feedback")
        Sentry.showReportDialog({
            title: "Report a bug",
            subtitle: "We're sorry that you're encountering issues.",
            successMessage: "Thank you for your feedback!",
            eventId
        })

        // this.close()
        // let wrapper = document.createElement("div")
        // wrapper.classList.add("raw_data_holder")
        //
        // let form = document.createElement("form")
        // form.onsubmit = (e) => e.preventDefault()
        //
        // let title_wrapper = document.createElement("div")
        // title_wrapper.innerHTML = "<div class=\"report_field text_field\"><span>A title for your issue</span> <label data-dashlane-label=\"true\"><input name=\"title\" placeholder=\"Give your issue a title\" class='input'></label></div>"
        //
        // let description_wrapper = document.createElement("div")
        // description_wrapper.innerHTML = "<div class=\"report_field text_field\"><span>A description of your issue</span> <label data-dashlane-label=\"true\"><textarea name=\"description\" class='input'></textarea></label></div>"
        //
        // let addfiles_wrapper = document.createElement("div")
        // addfiles_wrapper.innerHTML = "<div class=\"report_field text_field\"><span>Additional files</span> <label data-dashlane-label=\"true\"><input type='file' multiple name=\"files\" class='input'/></label></div>"
        //
        // let button = document.createElement("button")
        // button.innerHTML = "Submit report"
        // button.classList.add("button")
        // button.onclick = async () => {
        //     button.style.opacity = "0.5"
        //     button.disabled = true
        //
        //     let issue_id = makeid(20)
        //     // Upload items to the google cloud bucket
        //     let storage = getStorage(firebaseApp)
        //
        //     let failsafe = await this.DBClass.generateFailSafeFile()
        //     // @ts-ignore
        //     let files = [].concat(new File([failsafe], "failsafe.zip"), addfiles_wrapper.getElementsByClassName("input")[0].files)
        //
        //     for (let file of files) {
        //         let reference = ref(storage, `users/${tokenManager.gAuth.currentUser.uid}/issues/${issue_id}/${file.name}`)
        //         await uploadBytes(reference, file)
        //     }
        //
        //     console.log("Issue ID: " + issue_id)
        //
        //     fetch("/api/v3/issues/report", {
        //         method: "post",
        //         headers: {
        //             "content-type": "application/json",
        //             "authorization": "Bearer " + await tokenManager.token
        //         },
        //         body: JSON.stringify({
        //             // @ts-ignore
        //             title: title_wrapper.getElementsByClassName("input")[0].value,
        //             // @ts-ignore
        //             description: description_wrapper.getElementsByClassName("input")[0].value,
        //             source: "SnapshotWeb -> " + window.location.href,
        //             client_email: tokenManager.gAuth.currentUser.email,
        //             client_name: tokenManager.gAuth.currentUser.displayName,
        //             issue_id
        //         })
        //     })
        //         .then(res => {
        //             wrapper.remove()
        //             alert("Thank you for submitting a report!")
        //         })
        //         .catch(e => {
        //             alert("Ooops! We couldn't submit your error report. Please check your internet connection, and try again later.")
        //         })
        // }
        //
        // form.appendChild(title_wrapper)
        // form.appendChild(description_wrapper)
        // form.appendChild(addfiles_wrapper)
        // form.appendChild(button)
        // wrapper.appendChild(form)
        // document.body.appendChild(wrapper)
    }

    async close() {
        try {
            this.div.classList.add("deactivate")
            await wait(1000)
            this.div.parentElement?.removeChild(this.div)
        } catch (e) {
        }
    }
}

export class AppInfoIndicator {
    readonly div = document.createElement("div") as HTMLDivElement
    readonly storage_used_div = document.createElement("div") as HTMLDivElement
    readonly storage_available_div = document.createElement("div") as HTMLDivElement
    readonly progress_bar = document.createElement("progress") as HTMLProgressElement
    readonly sentry_release_div = document.createElement("div") as HTMLDivElement
    constructor() {
        this.div.appendChild(this.storage_used_div)
        this.div.appendChild(this.storage_available_div)
        this.div.appendChild(this.progress_bar)
        this.div.appendChild(this.sentry_release_div)

        this.storage_used_div.style.float = "left"
        this.storage_available_div.style.float = "right"
        this.progress_bar.style.width = "100%"

        this.sentry_release_div.style.fontSize = "8pt"
        this.sentry_release_div.style.fontStyle = "italic"
        this.sentry_release_div.style.color = "gray"
    }

    async render() {
        try {
            let storage_quota = await navigator.storage.estimate()
            this.storage_used_div.innerText = byteToReadableString(storage_quota.usage || 0)
            this.storage_available_div.innerText = byteToReadableString(storage_quota.quota || 0)
            this.progress_bar.value = (storage_quota.usage || Infinity) / (storage_quota.quota || Infinity)
        } catch(e) {console.error(e)}

        let release = (globalThis as {
            SENTRY_RELEASE?: {id: string}
        }).SENTRY_RELEASE?.id
        if (release) {
            this.sentry_release_div.innerText = "build " + release
        }
    }
}

function byteToReadableString(byteCount: number) {
    let result_number = byteCount
    let result_unit = "B"
    if (byteCount > 1e+9) {
        result_number = Math.round(byteCount / 1e+8) / 10
        result_unit = "GB"
    }
    else if (byteCount > 1e+6) {
        result_number = Math.round(byteCount / 100000) / 10
        result_unit = "MB"
    }
    else if (byteCount > 1000) {
        result_number = Math.round(byteCount / 100) / 10
        result_unit = "KB"
    }

    return result_number + result_unit
}