import {Database} from "./services/DBClass";
import {TABLES} from "../models/TABLES";
import {SupplierData} from "../models/DatabaseDataTypes";

export class Supplier {
    data: SupplierData

    static from(data: SupplierData) {return new Supplier(data)}
    static fromArray(data: SupplierData[]) {return data.map(i => new Supplier(i))}

    constructor(data: SupplierData) {
        this.data = data
    }

    farms() {
        return Database.getByIndex(TABLES.farms, "supplierId2", IDBKeyRange.only(this.data.uuid))
    }
}