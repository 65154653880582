import {Database} from "./DBClass";
import {AccountManager} from "./accountManager"
import {ManagerBaseClass} from "./ManagerBaseClass";
import {tokenManager} from "./tokenManager";
import {ActionQueue} from "../actionQueue";
import {Farm} from "../DatabaseData/farm";
import {GlobalEventsService} from "./events/globalEventsService";
import {TABLES} from "../../models/TABLES";
import {FarmData, OldFarmData, StorageStatus} from "../../models/DatabaseDataTypes";
import {SnapshotAPIError} from "../errorTypes/SnapshotAPIError";

export class FarmManagerClass extends ManagerBaseClass {
    public syncInProgress = false

    get [Symbol.toStringTag]() {return this.constructor.name}

    readonly progress_bar: HTMLElement

    constructor() {
        super();
        let progress_bar = document.getElementById("farm_dropdown") as HTMLElement
        if (progress_bar) progress_bar = progress_bar.getElementsByClassName("progress_bar")[0] as HTMLElement
        this.progress_bar = progress_bar
    }

    async sync() {
        this.dispatchEvent(new CustomEvent("sync_started"))

        if (this.syncInProgress) {
            console.warn("A farm sync is already in progress")
            return
        }

        // Confirm that the user is still online
        if (!navigator.onLine) {
            // this.dispatchEvent(new CustomEvent("sync_error", {
            //     detail: {
            //         type: "No connection",
            //         err: new Error("No connection")
            //     }
            // }))
            return false
        }

        this.syncInProgress = true

        if (this.progress_bar) {
            this.progress_bar.style.opacity = "1"
            this.progress_bar.style.marginTop = "10px"
            this.progress_bar.style.width = "0"
        }

        // Get account info
        let account = await AccountManager.get()
        let headers = new Headers()
        headers.set("Content-Type", "application/json")
        headers.set("Authorization", "Bearer " + await tokenManager.getToken())

        // List all of the farms that are already on the device
        let farms = (await this.getAll()).map(i => {
            return i.data.uuid
        })

        // Create action queue
        let queue = new ActionQueue()

        // Add farms that need uploading to the queue
        for (let farm of await Database.getByIndex(TABLES.farms, "storage_status", IDBKeyRange.only(StorageStatus.CLIENT_ONLY))) {
            queue.pushToQueue("Uploading farm: " + farm.uuid, () => this._upload(farm))
        }

        let loop_iteration = 0
        while (true) {
            try {
                let res = await fetch(`/api/v2/snapshot/farms?offset=${loop_iteration * 100}`, {
                    method: "GET",
                    headers
                })

                if (res.status === 401) {
                    GlobalEventsService.emit("onTokenExpired", {details: []})
                    return
                }
                let data = await res.json()
                if (data.code === 952) {
                    GlobalEventsService.emit("onTokenExpired", {details: []})
                    return
                }
                else if (data.code !== 0) break

                let batch = Database.batchOperation()

                // Create database transaction
                for (let farm of data.response as {fieldData: OldFarmData}[]) {
                    farm.fieldData.uuid = farm.fieldData["__PrimaryKey"]

                    let farmData: FarmData = {
                        partnerID: farm.fieldData["_PartnerID"],
                        address: {
                            postcode: farm.fieldData["Address|Delivery|Postcode"],
                            street: farm.fieldData["Address|Delivery|Street"],
                            street_address: farm.fieldData["Address|Delivery|StreetAddress"],
                            town: farm.fieldData["Address|Delivery|Town"],
                            city: farm.fieldData["Address|Delivery|City"]
                        },
                        creationTimestamp: new Date(farm.fieldData["CreationTimestamp"]),
                        modificationTimestamp: new Date(farm.fieldData["ModificationTimestamp"]),
                        milkSuppliedTo: farm.fieldData["MilkSuppliedTo"],
                        milkSupplierNumber: farm.fieldData["MilkSupplierNumber"],
                        uuid: farm.fieldData["__PrimaryKey"],
                        name: farm.fieldData["Business|Name"],
                        storageStatus: StorageStatus.SERVER_AND_CLIENT,
                        default: farm.fieldData["default"]
                    }

                    batch.put(TABLES.farms, farmData)

                    // Remove from the 'farms' list so that at the end, it only contains farms that are on the device, and not the server
                    if (farms.indexOf(data.uuid) !== -1) {
                        batch.delete(TABLES.farms, data.uuid)
                    }
                }

                await batch.commit()
                if (data.response.length < 100) break
                loop_iteration += 1

                // let temp = account
                // temp.lastFarmSync = (new Date()).getTime()
                // let __transaction = Database.indexedDB.transaction("account", "readwrite")
                // let __store = __transaction.objectStore("account")
                // __store.put(temp)
                //
                // // Delete old farms
                // for (let farm_id of farms) {
                //     __store.delete(farm_id)
                // }
                //
                // __transaction.commit()
            } catch(e) {
                console.error(e)
                break
            }
        }

        // Set up slow internet timer. This will trigger a 'slow internet' Notification if it takes too long for a Report to sync.

        queue.onItemComplete = (overallQueueLength, currentQueueLength, activeQueues) => {
            let decimal = (overallQueueLength - currentQueueLength) / overallQueueLength
            if (this.progress_bar) this.progress_bar.style.width = `calc(${Math.round(decimal * 100)} + 20px)%`
        }

        await queue.start()

        this.syncInProgress = false
        this.dispatchEvent(new CustomEvent("sync_completed"))
        if (this.progress_bar) {
            this.progress_bar.style.width = "calc(100% + 20px)"
            this.progress_bar.style.opacity = "0"
            this.progress_bar.style.marginTop = ""
        }
    }

    async _upload(farmData: FarmData) {
        let account = await AccountManager.get()

        let headers = new Headers()
        headers.set("Content-Type", "application/json")
        headers.set("Authorization", "Bearer " + await tokenManager.getToken())

        const res = await fetch(`/api/v2.1/snapshot/farms/${farmData.uuid}`,
            {
                method: "PATCH",
                headers,
                body: JSON.stringify(farmData)
            })
        const data = await res.json()

        if (data.code === 0) {
            // Farm uploaded successfully
            farmData.storageStatus = StorageStatus.SERVER_AND_CLIENT
            await Database.put(TABLES.farms, farmData)
            return
        }
        else if (data.code === 409) {
            // Server rejected request due to the farm already existing on the server
            let old_id = farmData.uuid
            farmData.uuid = data.response.uuid
            farmData.storageStatus = StorageStatus.SERVER_AND_CLIENT

            let batch = Database.batchOperation()
            batch.put(TABLES.farms, farmData)
            batch.delete(TABLES.farms, old_id)
            await batch.commit()

            await Database.writeByIndex(TABLES.tests, "farm_id", IDBKeyRange.only(old_id), (cursor) => {
                cursor.update({ ...cursor.value, farm_id: farmData.uuid });
            })
        }
        else {
            throw new SnapshotAPIError(data.code)
        }
    }

    async getAll() {
        return (await Database.getAll(TABLES.farms)).map(data => new Farm(data))
    }

    async save(data: FarmData) {
        await Database.put(TABLES.farms, data)
    }

    async get(farm_id: string): Promise<Farm> {
        let data = await Database.get(TABLES.farms, farm_id)
        return new Farm(data)
    }
}

export const FarmManager = new FarmManagerClass()