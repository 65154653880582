import {FirebaseOptions, initializeApp} from "firebase/app";

const firebaseConfig: FirebaseOptions = {
    apiKey: "AIzaSyDgHhUSM_9qw5BlTlzjoYGD8AOo4sI78a0",
    authDomain: "handy-buttress-365603.firebaseapp.com",
    projectId: "handy-buttress-365603",
    storageBucket: "handy-buttress-365603.appspot.com",
    messagingSenderId: "1033156063621",
    appId: "1:1033156063621:web:fb8c0a22184a5d5f4b14ec",
};

export const firebaseApp = initializeApp(firebaseConfig)