import {BaseEventsService, BasicEventDetails, BasicEventsList, EventHandler} from "./baseEventsService";

export enum QUEUE_TYPES {
    Report,
    Farm
}

interface SyncEventDetails extends BasicEventsList {
    "onQueueStart": QueueEvent
    "onQueueUpdate": QueueUpdateEvent
    "onQueueEnd": QueueEvent
    "onQueueError": QueueErrorEvent
}

export interface QueueEvent extends BasicEventDetails {
    details: [],
    extraData: {
        queue: QUEUE_TYPES
    }
}

export interface QueueUpdateEvent extends BasicEventDetails {
    details: [{
        complete: number,
        processing: number,
        total: number
    }],
    extraData: {
        queue: QUEUE_TYPES
    }
}

export interface QueueErrorEvent extends BasicEventDetails {
    details: [{
        error: unknown
    }],
    extraData: {
        queue: QUEUE_TYPES
    }
}

export class SyncStatusEventsServiceClass extends BaseEventsService<{
    queue: QUEUE_TYPES
}, SyncEventDetails> {
    protected eventFilter<T extends keyof SyncEventDetails>(message: {
        event: T;
        eventData: SyncEventDetails[T]
    }, handler: EventHandler<SyncEventDetails[T]["details"], { queue: QUEUE_TYPES }>): boolean {
        return message.eventData.extraData.queue === handler.extraData.queue
    }
}

export const SyncStatusEventsService = new SyncStatusEventsServiceClass()