import {Field} from "./field";
import {Database} from "../../services/DBClass";
import {FieldData, HeaderData} from "../../../models/DatabaseDataTypes";
import {DatabaseBatchOperation} from "../../databaseBatchOperation";
import {TABLES} from "../../../models/TABLES";

export class Header {
    headerId: string
    data: HeaderData
    constructor(headerId: string, data: HeaderData) {
        this.headerId = headerId
        this.data = data

        // this.manager.addEventListener("header_id_changed", new WeakRef((e) => {
        //     if (e.detail.old === this.headerId) this.headerId = e.detail.new
        // }).deref())
    }

    get() {
        return (Database.get(TABLES.headers, this.headerId)
            .then(e => {
                this.data = e
                return e
            }))
    }

    async fields(returnRawData = false, returnFieldDivClass = false) {
        let fields = await this.getRawFieldData()
        return fields.map(i => new Field(i.uuid, i))
    }

    async getRawFieldData(): Promise<FieldData[]> {
        let fields = await Database.getByIndex(TABLES.fields, "headerId", IDBKeyRange.only(this.headerId))
        fields = fields.sort((a, b) => {
            if (a.pos > b.pos) return 1
            else if (a.pos === b.pos) return 0
            else return -1
        })
        return fields
    }

    async delete(batch?: DatabaseBatchOperation) {
        let commit_batch = !batch
        if (!batch) batch = Database.batchOperation()
        // If no batch operation was passed in, create a batch operation and commit it at the end

        for (let field of await this.fields()) await field.delete(batch)

        batch.delete(TABLES.headers, this.headerId)
        if (commit_batch) await batch.commit()
    }
}