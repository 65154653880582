import {AccountManager} from "../utils/services/accountManager";

export class ReLoginScreen {
    readonly div: HTMLElement
    protected content: HTMLElement
    protected iframe: HTMLIFrameElement

    constructor(show_fade_in = true) {
        this.div = document.createElement("div")
        this.div.classList.add('relogin_wrapper')

        this.content = document.createElement("div")
        this.content.classList.add("splashscreen_content")

        this.iframe = document.createElement("iframe")
        this.iframe.src = "/relogin"
        this.iframe.width = "300"
        this.iframe.height = "334"
        this.iframe.classList.add("relogin_iframe")
        this.content.appendChild(this.iframe)

        // Listen for messages from the iframe
        window.addEventListener("message", msg => {
            if (msg.source !== this.iframe.contentWindow) return;
            if (msg.data.type === "reload") window.location.reload();
            if (msg.data.type === "logout") AccountManager.logout();
        })

        this.div.appendChild(this.content)

        if (show_fade_in) {
            this.div.style.animation = "splashScreenFadeIn 1s";
        }
        document.body.appendChild(this.div)
    }

    delete() {
        this.div.style.animation = "splashScreenFadeOut 1s";
        setTimeout(() => {
            document.body.removeChild(this.div)
        }, 1000)
    }
}