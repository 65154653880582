export enum TestStatus {
    ARCHIVED,
    FARM_INPUT,
    FARM_WAITING_TO_UPLOAD,
    LAB_READY,
    LAB_PROCESSING,
    LAB_FINALISING,
    FARM_PDF
}

export enum FieldTypes {
    TEXT_FIELD = "text_field",
    TEXT_AREA = "text_area",
    RADIO_SELECT = "radio_select",
    MULTI_SELECT = "multi_select",
    NUMBER_FIELD = "number_field",
    DATE = "date",
    TIMESTAMP = "timestamp",
    TIMESTAMP_TIMER = "timestamp_timer",
    SELECT_LIST = "select_list",
    IMAGE_UPLOAD = "image_upload",
    LABEL = "label",
    ADDRESS = "address"
}

export interface AccountData {
    "Name|Username": string;
    "Name|FName": string;
    "Name|LName": string;
    "EmailAddress": string;
    "LandlineNumber": string;
    "LastLogin": string;
    "StreetAddress": string;
    "L_Farm|Partner::CompanyName": string;
    "L_Farm|Partner::PrimaryColor": string;
    "L_Farm|Partner::SecondaryColor": string;
    "__PrimaryKey": string;
    "MobileNumber": string;
    "ChangePassword|NewPassword": string;
    "ChangePassword|OldPassword": string;
    "_ID|Partner": string;
    "FirebaseID": string;
    "FluentFormsData1": string;
    "FluentFormsData2": string;
    "FF|Email": string;
    "CreationTimestamp": string;
    "recordId": string;
    "sessionToken": string | null;
    "id": string;
    "dataVersion": number[];
    "lastFarmSync": number;
    "lastReportSync": number;
}

export interface OldFarmData {
    "_PartnerID": string,
    "_ReportDefault": string,
    "Address|Delivery|Postcode": string,
    "Address|Delivery|Street": string,
    "Address|Delivery|StreetAddress": string,
    "Address|Delivery|Town": string,
    "Address|Delivery|City": string,
    "CreationTimestamp": string,
    "MilkSuppliedTo": string,
    "MilkSupplierNumber": string,
    "ModificationTimestamp": string,
    "ModificationTimestamp|JS": Date,
    "WEB|Farms|Partners|Users::__PrimaryKey": string,
    "__PrimaryKey": string,
    "Business|Name": string,
    "WEB|Farms|Supplier::SupplierName": string,
    "WEB|Farms|Supplier::Priority": string,
    "uuid": string,
    storageStatus: StorageStatus,
    "recordId": string,
    default: any
}

export interface FarmData {
    partnerID: string,
    address: {
        postcode: string,
        street: string,
        street_address: string,
        town: string,
        city: string,
    },
    creationTimestamp: Date,
    modificationTimestamp: Date,
    milkSuppliedTo: string,
    milkSupplierNumber: string,
    uuid: string,
    name: string,
    storageStatus: StorageStatus
    default?: any
}

export interface FieldData {
    name: string,
    pos: number,
    type: FieldTypes,
    data: string,
    fmFieldName: string,
    uuid: string,
    changed: boolean,
    headerId: string,
    content: string | number,
    indentLevel?: number,
    condition?: string,
    validation?: string
    other?: string
    reportId: string
}

export interface HeaderData {
    title: string,
    collapsable: string,
    uuid: string,
    sectionId: string,
    pos: string,
    reportId: string
}

export interface ArchiveData {
    data: Blob,
    uuid: string,
    storageStatus: StorageStatus,
    farmId?: string,
    sampleClass?: string,
    delete?: boolean,
    reportId?: string
}

export interface SectionData {
    title: string,
    uuid: string,
    reportId: string,
    pos: string
}

export interface SupplierData {
    uuid: string,
    name: string
}

// Values in this enum are explicitly determined, to make sure they are consistent between updates
export enum StorageStatus {
    CLIENT_ONLY = 0,
    SERVER_ONLY = 1,
    SERVER_AND_CLIENT = 2
}

export interface TestData {
    uuid: string,
    status: TestStatus,
    storageStatus: StorageStatus,
    farm_id: string,
    name: string,
    contractorNote: string,

    /*
     * @deprecated Use other methods to get the sections for a report/test
     */
    formSections: SectionData[]

    serverHash: string,
    lastModified: Date | number,
    recordId: string,
    pdfId?: string,
    // pdf?: Blob,
    uploadData?: ReportUploadData,
}

export interface ReportUploadData {
    data: any
    // images_to_upload: DBImage[]
}

export interface ReportUploadDataExtended {
    data: {
        [key: string]: {
            value: Date | number | string
            changed: boolean
        }
    }
}

export interface IDBRequestEvent<data extends object> extends Event {
    target: IDBRequest | null,
    error: DOMException | null
}