import {Workbox} from 'workbox-window';
import {Notification} from "../../components/notification";
import {getMessaging, getToken, onMessage} from "firebase/messaging"
import {firebaseApp} from "../Firebase/firebaseApp";
import {onAuthStateChanged} from "firebase/auth"
import {tokenManager} from "../services/tokenManager";
import {firestore} from "../Firebase/firestore";
import {addDoc, collection} from "firebase/firestore";

/** @preserve **/
export let WORKER: Workbox | null = null
const messaging = getMessaging(firebaseApp)
export let pushToken: null | string = null
let registration: ServiceWorkerRegistration

export async function startServiceWorker() {
    if (!WORKER) WORKER = new Workbox("/sw.min.js")
    WORKER.addEventListener("waiting", (e) => {
        Notification.thisTab("<i class=\"fa-solid fa-download\"></i>", "A new update has been installed! You'll see some changes when you next reopen this app.")

        WORKER?.messageSkipWaiting()
    })

    let _registration = await WORKER.register()
    if (!_registration) throw new Error("Failed to register service worker")
    registration = _registration;
    return registration
}

export async function restartServiceWorker() {
    let registration = await startServiceWorker()
    await registration.unregister()
    return await startServiceWorker()
}

onAuthStateChanged(tokenManager.gAuth, async (user) => {
    if (registration?.active && user) {
        try {
            pushToken = await getToken(messaging, {
                vapidKey: "BGqvbbY8LOZYLx2z1cvjxBvl0bX-_uomKrfj_MlHqkg1tt3FTcqoGNiYC_T3r0Vr1FEIsmLc8gpKHAxQNU8EsYQ",
                serviceWorkerRegistration: registration
            })

            // Set up session in firestore
            await addDoc(collection(firestore, "users", user.uid, "sessions"), {
                os: navigator.userAgent,
                osBuild: null,
                osVersion: null,
                pushToken: pushToken,
                registered: new Date()
            })
            console.log("Push notifications registered")
        } catch (e) {
            console.error(e)
        }
    }
})

onMessage(messaging, (payload) => {
    if (payload.notification && document) Notification.thisTab("<i class=\"fa-solid fa-message-bot\"></i>", payload.notification.body || "", true)
    console.log("Message received", payload)
})