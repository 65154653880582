export class ManagerBaseClass extends EventTarget {
    get [Symbol.toStringTag]() {return this.constructor.name}
    constructor() {
        super();
    }

    processFMError(code: number | string) {
        if (code == 952 || code == 401) return
        this.dispatchEvent(new CustomEvent("fm_error", {
            detail: {
                type: "FM error code",
                err: new Error("FM ERROR CODE: " + code)
            }
        }))
    }

    handleSocketMessage(msg: any) {}
}