import {
    ArchiveData,
    FieldTypes,
    ReportUploadData,
    ReportUploadDataExtended,
    SectionData,
    StorageStatus,
    TestData,
    TestStatus
} from "../../../models/DatabaseDataTypes";
import {tokenManager} from "../../services/tokenManager";
import JSZip from "jszip";
import {Database} from "../../services/DBClass";
import {Field} from "./field";
import {APIResponse} from "../../../models/APIResponse";
import {makeid} from "../../utilities";
import {Header} from "./header";
import {BasicReportEvent, GLOBAL_EVENTS, GlobalEventsService} from "../../services/events/globalEventsService";
import {SnapshotStructure} from "../SnapshotStructure";
import {DatabaseBatchOperation} from "../../databaseBatchOperation";
import {downloadBlob} from "../../downloadBlob";
import {DBImage} from "./DBImage";
import {TABLES} from "../../../models/TABLES";
import {Section} from "./section";

export interface reportUpload extends Report {
    newRecordId: number
    new_report: Report
}

export interface ReportArchiveData extends ArchiveData {
    class: string
}

export interface ReportData {
    id: string,
    recordId: number,
    status: number,
    farmID: string,
    hash: string,
    lastModified: Date,
    archive: ReportArchiveData[],
    data?: any
}

enum API_VERSIONS {
    V2,
    V2_1,
    V3
}

export class Report {
    data: TestData

    get [Symbol.toStringTag]() {return this.constructor.name}
    static fromArray(data: TestData[]) {
        return data.map(i => new this(i))
    }
    static from(data: TestData) {return new this(data)}
    static async buildReport(structure: SnapshotStructure, emitEvent = true, batch?: DatabaseBatchOperation) {
        let commit_batch = !batch
        if (!batch) batch = Database.batchOperation()

        for (let i in structure.formSections) {
            let section = structure.formSections[i]
            section.uuid = structure.uuid + "_" + section.title.toLowerCase().replace(/[^a-z]/g, "")
            section.reportId = structure.uuid
            section.pos = i

            for (let r in section.headers) {
                let header = section.headers[r]
                header.uuid = structure.uuid + "_" + header.title.toLowerCase().replace(/[^a-z]/g, "")
                header.sectionId = section.uuid
                header.pos = r

                for (let d in header.fieldData) {
                    let field = header.fieldData[d]
                    // Field.pos = d
                    if (field.content === field.fmFieldName) continue

                    field.uuid = structure.uuid + "_" + (field.fmFieldName || makeid(21))
                    field.changed = false
                    field.headerId = header.uuid
                    batch.put(TABLES.fields, field)
                    field.reportId = structure.uuid
                }

                // @ts-expect-error
                delete header.fieldData
                batch.put(TABLES.headers, header)
                header.reportId = structure.uuid
                // events.push(new CustomEvent("header_update", {detail: header}))
            }

            // @ts-expect-error
            delete section.headers
            batch.put(TABLES.sections, section)
            section.reportId = structure.uuid
            // events.push(new CustomEvent("section_update", {detail: section}))
        }

        // delete structure.formSections
        batch.put(TABLES.tests, structure)

        if (commit_batch) await batch.commit()
        // Broadcast upload event
        if (emitEvent) GlobalEventsService.emit("onReportEvent", {details: [{...structure, type: "built"}]})
        return new Report(structure)
    }

    constructor(data: TestData) {
        this.data = data
    }

    get recordId() {
        return this.data.recordId
    }

    // ----------------------- EVENTS -----------------------
    protected onNewReportID(oldId: string, newID: string) {}
    protected onReportBuilt(e: BasicReportEvent) {}
    protected onReportUploaded(e: BasicReportEvent) {}
    protected onReportUploading(e: BasicReportEvent) {}
    protected onReportDownloaded(e: BasicReportEvent) {}
    protected onReportDownloading(e: BasicReportEvent) {}
    protected onReportModified(e: BasicReportEvent) {}
    protected onReportDeleted(e: BasicReportEvent) {}

    readonly eventHandler = (e: BasicReportEvent & {event: string}) => {
        if (e.details[0].uuid === this.data.uuid) {
            if (e.details[0].new_id) {
                let old_id = this.data.uuid
                this.data.uuid = e.details[0].new_id
                this.onNewReportID(old_id, this.data.uuid)
            }
            this.data = e.details[0]
            switch (e.details[0].type) {
                case "built": return this.onReportBuilt(e)
                case "uploaded": return this.onReportUploaded(e)
                case "uploading": return this.onReportUploading(e)
                case "downloaded": return this.onReportDownloaded(e)
                case "downloading": return this.onReportDownloading(e)
                case "modified": return this.onReportModified(e)
                case "deleted": return this.onReportDeleted(e)
            }
        }
    }

    load() {
        GlobalEventsService.on("onReportEvent", this.eventHandler, null)
    }

    unload() {
        GlobalEventsService.removeEventListener("onReportEvent", this.eventHandler)
    }

    emit(eventType: GLOBAL_EVENTS, extraData: unknown = {}) {
        GlobalEventsService.emit(eventType, {details: [this.data], extraData})
    }
    // ----------------------- EVENTS -----------------------



    // ---------------- LOCAL DATA FETCHING ----------------
    async sections(): Promise<SectionData[]> {
        let sections = (await Database.getAll(TABLES.sections))
            .filter(i => i.reportId === this.data.uuid)

        sections.sort((a, b) => {
            if (a.pos > b.pos) return 1
            else if (a.pos === b.pos) return 0
            else return -1
        })

        return sections
    }

    async headers() {
        let headers = (await Database.getAll(TABLES.headers))
            .filter(i => i.reportId === this.data.uuid)

        headers.sort((a, b) => {
            if (a.pos > b.pos) return 1
            else if (a.pos === b.pos) return 0
            else return -1
        })

        let out: Header[] = []
        for (let i in headers) {
            out.push(new Header(headers[i].uuid, headers[i]))
        }
        return out
    }

    async fields() {
        // let fields = (await Database.getAll<FieldData>(TABLES.fields))
        //     .filter(i => i.reportId === this.data.uuid)

        let fields = await Database.getByIndex(
            TABLES.fields, "reportId", IDBKeyRange.only(this.data.uuid)
        )

        fields.sort((a, b) => {
            if (a.pos > b.pos) return 1
            else if (a.pos === b.pos) return 0
            else return -1
        })

        let out: Field[] = []
        for (let i in fields) {
            out.push(new Field(fields[i].headerId, fields[i]))
        }
        return out
    }

    async farm() {
        return await Database.get(TABLES.farms, this.data.farm_id)
    }

    refresh() {
        return Database.get(TABLES.tests, this.data.uuid)
            .then(e => {
                if (e === undefined) {
                    return undefined
                }
                else {
                    this.data = e
                    return this
                }
            }).catch(e => {
                return undefined
            })
    }
    // ---------------- LOCAL DATA FETCHING ----------------




    // -------------- LOCAL DATA MANIPULATION --------------
    // Save any changes made to this.data
    async save(batch?: DatabaseBatchOperation) {
        if (batch) batch.put(TABLES.tests, this.data, (err) => {
            if (!err) this.emit("onReportModified")
        })
        else {
            await Database.put(TABLES.tests, this.data)
            // Broadcast downloaded event
            this.emit("onReportModified")
        }
        return
    }

    async delete(batch?: DatabaseBatchOperation, triggerEvent = true) {
        // Trigger delete event
        if (triggerEvent) {
            this.emit("onReportDeleted")
        }

        let commit_batch = !batch
        if (!batch) batch = Database.batchOperation()
        // If no batch operation was passed in, create a batch operation and commit it at the end

        for (let section of await this.sections()) await Section.from(section).delete(batch)

        batch.delete(TABLES.tests, this.data.uuid)
        if (commit_batch) await batch.commit()
    }

    async generateUploadDataExtended(fullUpload = false, fields: Field[] = []) {
        let return_data: ReportUploadDataExtended = {data: {}}

        if (fields.length === 0) {
            for (let section of Section.fromArray(await this.sections())) {
                for (let header of await section.headers()) {
                    for (let field of await header.fields()) {
                        fields.push(field)
                    }
                }
            }
        }

        // Get all fields that have been changed
        for (let field of fields) {
            // Process the Field
            let field_data = await field.get()
            let value = ""

            if (field_data.changed === false && (!fullUpload)) {
                continue
            }

            switch (field_data.type) {
                case FieldTypes.LABEL:
                    break
                case FieldTypes.IMAGE_UPLOAD:
                    let images = await field.getImages()

                    value = JSON.stringify(images.map(image => image.data.uuid))
                    break
                case FieldTypes.TIMESTAMP:
                    try {
                        value = new Date(field_data.content).toISOString()
                    } catch (e) {
                        value = ""
                    }
                    break
                case FieldTypes.MULTI_SELECT:
                    value = field.data.content?.toString() || ""
                    break
                case FieldTypes.RADIO_SELECT:
                    value = field.data.other || field.data.content.toString() || ""
                    break
                case FieldTypes.SELECT_LIST:
                    value = field.data.other || field.data.content.toString() || ""
                    break
                default:
                    value = field_data.content.toString() || ""
                    if (typeof field_data.other !== "undefined" && field_data.other !== "") {
                        value += field_data.other
                    }
            }

            return_data.data[field_data.fmFieldName] = {
                value,
                changed: field_data.changed
            }
        }
        return return_data
    }
    async generateUploadData(fullUpload = false, fields: Field[] = []): Promise<ReportUploadData> {
        let return_data: any = {}

        if (fields.length === 0) {
            for (let section of Section.fromArray(await this.sections())) {
                for (let header of await section.headers()) {
                    for (let field of await header.fields()) {
                        fields.push(field)
                    }
                }
            }
        }

        // Get all fields that have been changed
        for (let field of fields) {
            // Process the Field
            if (field.data.changed === false && (!fullUpload)) {
                continue
            }

            switch (field.data.type) {
                case FieldTypes.LABEL:
                    break
                case FieldTypes.IMAGE_UPLOAD:
                    // Check that all images are uploaded.
                    let images = await field.getImages()

                    return_data[field.data.fmFieldName] = JSON.stringify(images.map(image => image.data.uuid))
                    break
                case FieldTypes.TIMESTAMP:
                    try {
                        return_data[field.data.fmFieldName] = new Date(field.data.content).toISOString()
                    } catch (e) {
                        return_data[field.data.fmFieldName] = ""
                    }
                    break
                case FieldTypes.MULTI_SELECT:
                    return_data[field.data.fmFieldName] = field.data.content || ""
                    break
                case FieldTypes.RADIO_SELECT:
                    return_data[field.data.fmFieldName] = field.data.other || field.data.content || ""
                    break
                case FieldTypes.SELECT_LIST:
                    return_data[field.data.fmFieldName] = field.data.other || field.data.content || ""
                    break
                default:
                    return_data[field.data.fmFieldName] = field.data.content || ""
                    if (typeof field.data.other !== "undefined" && field.data.other !== "") {
                        return_data[field.data.fmFieldName] += field.data.other
                    }
            }
        }
        return {data: return_data}
    }


    private async saveFieldData(data: {
        [fieldName: string]: string | number
    }, onProcessField = (field: Field) => {}) {
        let fields = await this.fields()
        let batch = Database.batchOperation()

        for (let field of fields) {
            field.data.changed = false
            if (data[field.data.fmFieldName]) field.data.content = data[field.data.fmFieldName]
            onProcessField(field)
            field.save(batch)
        }
        await batch.commit()
    }

    async processDownloadedData(data: any, storageStatus: StorageStatus = StorageStatus.SERVER_AND_CLIENT) {
        let zip_files: {
            [archiveId: string]: string
        } = {}
        if (!data.archive) data.archive = data.portalData["Reports|Archive"]
        await this.saveFieldData(data.data, (field: Field) => {
            if (field.data.type === FieldTypes.IMAGE_UPLOAD) {
                for (let image of data.archive.filter((img: any) => img["Reports|Archive::SampleClass"] === field.data.fmFieldName)) {
                    zip_files[image['Reports|Archive::__PrimaryKey']] = field.uuid
                }
            }
        })

        this.data = {
            uuid: data.data.uuid,
            storageStatus,
            farm_id: data.data.farm_id,
            formSections: [],
            serverHash : data.fieldData["_Report|JSON|Shorthand|HASH"],
            lastModified : data.fieldData["__ModificationTimestamp|JS"],
            recordId : data.recordId || data.data.recordId || this.data.recordId,
            status : data.data.status,
            name : data.data.name,
            contractorNote : data.data.contractorNote,
            pdfId: data.fieldData["_PDF|ArchiveID"]
        }

        await this.save()

        // DOWNLOAD IMAGES
        await this.downloadImages(zip_files)

        // DOWNLOAD PDF
        if (data.fieldData["_PDF|ArchiveID"]) this.downloadNewPDF(data.fieldData["_PDF|ArchiveID"])

        // Broadcast downloaded event
        this.emit("onReportDownloaded")
    }
    // -------------- LOCAL DATA MANIPULATION --------------




    // --------------- SERVER COMMUNICATIONS ---------------
    getZIP(): ReturnType<typeof JSZip.loadAsync> {
        return new Promise(async (resolve, reject) => {
            let headers = new Headers()
            headers.set("Content-Type", "application/json")
            headers.set("Authorization", "Bearer " + await tokenManager.getToken())

            let controller = new AbortController();
            setTimeout(() => {
                controller.abort("Took too long to download")
            }, 10000)

            fetch("/api/v2/snapshot/reports/" + this.data.uuid + "/zip", {
                method: "GET",
                headers,
                signal: controller.signal
            })
                .then(res => {
                    if (res.status === 401) {
                        Database.events.tokenExpired()
                        throw "token expired"
                    }
                    else if (res.status >= 400) {
                        throw "Unknown server communication error"
                    }

                    return res.blob()
                })
                .then(blob => {
                    return JSZip.loadAsync(blob)
                })
                .then(zip => {
                    resolve(zip)
                })
                .catch(e => {
                    reject(e)
                })
        })
    }

    async images() {
        let images = await Database.getByIndex(TABLES.archive, "reportId", IDBKeyRange.only(this.data.uuid))
        return images.map(i => new DBImage(i))
    }

    async upload(headers: Headers) {
        let submit = this.data.status === TestStatus.FARM_WAITING_TO_UPLOAD

        let upload_data = await this.generateUploadDataExtended(true)
        const res = await fetch("/api/v2.1/snapshot/reports/" + this.data.uuid + "/", {
            method: "PATCH",
            headers,
            body: JSON.stringify({
                fieldData: upload_data.data,
                farmId: this.data.farm_id
            })
        });

        let data: APIResponse<any> = await res.json()
        if (res.status === 422) {
            // The server rejected the request
            throw new Error("The server reject the uploading of report data")
        }
        else if (data.code !== 0) throw new Error("API returned an error")

        // Upload images
        await this.uploadImages()

        // Save data
        if (!submit) this.data.status = data.response.status
        this.data.farm_id = data.response.farmID
        this.data.recordId = data.response.recordId
        let zip_files: {
            [archiveId: string]: string
        } = {}
        await this.save()
        await this.saveFieldData(data.response.data, (field: Field) => {
            if (field.data.type === FieldTypes.IMAGE_UPLOAD) {
                for (let image of data.response.archive.filter((img: any) => img["Reports|Archive::SampleClass"] === field.data.fmFieldName)) {
                    zip_files[image['Reports|Archive::__PrimaryKey']] = field.uuid
                }
            }
        })

        // DOWNLOAD IMAGES
        await this.downloadImages(zip_files)

        // Broadcast upload event
        this.emit("onReportUploaded")

        if (submit) {
            // Submit the report
            await this.submit(headers)
        }
    }

    async submit(headers: Headers) {
        const res = await fetch(`/api/v2.1/snapshot/reports/${this.data.uuid}/submit`, {
            method: "PATCH",
            headers
        })
        if (res.status === 422) {
            this.data.status = TestStatus.FARM_INPUT
            await this.save()
            throw new Error("Server rejected submission of a report")
        }
        else if (res.status === 200) {
            // Successful submission
            let data: APIResponse<{status: number}> = await res.json()
            if (data.code === 0) {
                this.data.status = data.response.status
                await this.save()
            }
        }
    }

    async downloadImages(imageFieldMap: { [archiveId: string]: string }) {
        // Download the ZIP file
        let headers = new Headers()
        headers.set("Authorization", "Bearer " + await tokenManager.getToken())
        const file = await (fetch("/api/v2/snapshot/reports/" + this.data.uuid + "/zip",
            {method: "get", headers}
        ))
        const zip = await JSZip.loadAsync(await file.blob())
        let items_to_process: any[] = []
        zip.forEach((path, file) => {
            let item = Object.keys(imageFieldMap).find(i => path.startsWith(i))
            if (item) {
                items_to_process.push([path, file])
            }
        })

        for (let i in items_to_process) {
            await Database.put(TABLES.archive, {
                data: await items_to_process[i][1].async("blob"),
                sampleClass: imageFieldMap[items_to_process[i][0]],
                storageStatus: StorageStatus.SERVER_AND_CLIENT,
                uuid: items_to_process[i][0]
            })
        }
    }

    async download(headers: Headers, overwriteReportID: string = this.data?.uuid) {
        if (!navigator.onLine) throw new Error("Client is not online!")
        const req = await fetch("/api/v2/snapshot/reports/" + overwriteReportID, {
            method: "GET",
            headers
        })
        const data = await req.json()
        await this.processDownloadedData(data.response)
        this.emit("onReportDownloaded")
    }

    async downloadNewPDF(pdfId: string) {
        let token = await tokenManager.getToken()
        if (!token) throw new Error("Unable to obtain token")
        let pdf = await downloadBlob("get", `/api/v2/archive/items/${pdfId}/stream`, token)
        if (pdf.type !== "application/pdf") {
            // Try again 1 more time, incase the server needed a wake-up
            pdf = await downloadBlob("get", `/api/v2/archive/items/${pdfId}/stream`, token)
            if (pdf.type !== "application/pdf") throw new Error("Invalid PDF; " + pdfId)
        }
        let pdf_data: ArchiveData = {
            data: pdf,
            storageStatus: StorageStatus.SERVER_AND_CLIENT,
            uuid: pdfId
        }

        Database.put(TABLES.archive, pdf_data)
        this.data.pdfId = pdfId
        await this.save()
        return pdf
    }

    async getPDF(): Promise<ArchiveData | null> {
        return this.data.pdfId ? (await Database.get(TABLES.archive, this.data.pdfId)) : null
    }

    async uploadImages(images?: DBImage[]) {
        if (!images) images = (await this.images()).filter(i => i.data.storageStatus === StorageStatus.CLIENT_ONLY)
        for (let image of images) {
            if (!image.data.sampleClass) continue
            let field = await Field.getField(image.data.sampleClass)
            if (field) await image.upload(field.data.fmFieldName)
        }
    }
    // --------------- SERVER COMMUNICATIONS ---------------
}