import {GlobalEventsService} from "../utils/services/events/globalEventsService";

export class Notification {
    protected div: HTMLElement
    protected iconDiv: HTMLElement
    protected htmlDiv: HTMLElement
    protected slider: HTMLElement | null = null
    protected dismissButton: HTMLButtonElement | null = null

    static thisTab(iconHTML: string, html: string, autoDismiss = true) {
        return new this(iconHTML, html, autoDismiss)
    }

    static broadcast(iconHTML: string, html: string, autoDismiss = true) {
        GlobalEventsService.emit("onInAppNotification", {
            details: [{
                type: "normal",
                iconHTML, html, autoDismiss
            }]
        })
    }

    protected constructor(iconHTML: string, html: string, autoDismiss = true) {
        this.div = document.createElement("div")
        this.div.classList.add("notification_top")
        this.div.classList.add("notification_top_anim")
        setTimeout(() => {
            this.div.classList.remove("notification_top_anim")
        }, 2000)
        this.iconDiv = document.createElement("div")
        this.iconDiv.innerHTML = iconHTML
        this.htmlDiv = document.createElement("div")
        this.htmlDiv.innerHTML = html
        this.div.appendChild(this.iconDiv)
        this.div.appendChild(this.htmlDiv)
        document.body.appendChild(this.div)

        if (autoDismiss) {
            this.slider = document.createElement("div")
            this.slider.classList.add("notification_slider")
            this.div.appendChild(this.slider)
            setTimeout(() => {
                this.dismiss()
            }, 10000)
        }
        else {
            this.dismissButton = document.createElement("button")
            this.dismissButton.classList.add("notification_dismiss_btn")
            this.dismissButton.innerHTML = "<i class=\"fa-solid fa-xmark\"></i>"
            this.div.appendChild(this.dismissButton)
            this.dismissButton.onclick = () => {
                this.dismiss()
            }
        }

    }

    async dismiss() {
        this.div.classList.add("notification_top_dismiss")
        setTimeout(() => {
            this.div.parentElement?.removeChild(this.div)
        }, 3000)
    }
}

// Only register the event on JS instances that have access to a DOM.
if (self.document) GlobalEventsService.on("onInAppNotification", (notification) => {
    if (notification.details[0].type !== "normal") return
    Notification.thisTab(notification.details[0].iconHTML, notification.details[0].html, notification.details[0].autoDismiss)
}, null)