import {Database} from "./DBClass";
import {SupplierManager} from "./supplierManager";
import {tokenManager} from "./tokenManager";
import {SplashScreen} from "../../components/splashScreen";
import {version} from "../UpdateControl/version";
import {WORKER} from "../ServiceWorker/domServiceWorkerUtilities";
import {TABLES} from "../../models/TABLES";
import {AccountData} from "../../models/DatabaseDataTypes";

export class AccountManagerClass extends EventTarget {
    private firebaseLogoutHandler

    get [Symbol.toStringTag]() {return this.constructor.name}
    constructor() {
        super()
        this.firebaseLogoutHandler = () => {
        }
        this.firebaseLogoutHandler.bind(this)

        tokenManager.addEventListener("firebase_logout", this.firebaseLogoutHandler)
        tokenManager.gAuth.onAuthStateChanged((user) => {
            this.dispatchEvent(new CustomEvent("firebase_auth_changed", {detail: user}))
        })
    }

    async signInEmail(email: string, password: string) {
        await tokenManager.signInEmail(email, password)
        await this.fetchAccountData()
    }

    async fetchAccountData() {
        let headers = new Headers()
        headers.set("Content-Type", "application/json")
        headers.set("Authorization", "Bearer " + await tokenManager.getToken())
        let req = await fetch('/api/v2/account?includelogos=true', {
            method: "GET",
            headers
        })
        let data = await req.json() as any
        if (data.code === 0) {
            // await wait(10000)

            let account_details = data.response.fieldData
            account_details.recordId = data.response.recordId
            account_details.sessionToken = req.headers.get("X-FM-Data-Access-Token")
            account_details.id = "account"
            account_details.dataVersion = [1, 0, 2, 24]

            // Add all suppliers to database
            SupplierManager.addAll(data.response.portalData["L_User|SupplierToOrganisation|Supplier"].map((supplier: any) => {
                return {
                    uuid: supplier["L_User|SupplierToOrganisation|Supplier::__PrimaryKey"],
                    name: supplier["L_User|SupplierToOrganisation|Supplier::SupplierName"]
                }
            }))

            // convert logos to base64
            account_details["Logo|Full"] = account_details["Logo|Full"]
            account_details["Logo|Icon"] = account_details["Logo|Icon"]
            account_details["dataVersion"] = version

            return this.set(account_details)
        }
        else {
            throw new Error("Login failed: no token provided")
        }
    }

    forgotPassword(email: string) {
        return tokenManager.forgotPassword(email)
    }

    async get() {
        return await Database.get(TABLES.account, "account")
    }

    async set(account_details: AccountData) {
        await Database.put(TABLES.account, account_details)
    }

    changePassword(oldPassword: string, newPassword: string) {
        return tokenManager.changePassword(oldPassword, newPassword)
    }

    /*
    NOTE: This method is only step 1 of the logout process.

    STEP 1 (AccountService): Invalidate the user's firebase token
    STEP 2 (ServiceWorker): Redirect all active pages to the logout page
    STEP 3 (Logout page): Unregister the service worker
    STEP 4 (Logout page): Delete the local data
    STEP 5 (Logout page): Redirect to the login page
     */

    async logout(doConfirmation = true) {
        if (doConfirmation) {
            if (!confirm("Are you sure you want to logout? All non-uploaded data will be deleted.")) return false
        }

        // Show splash screen
        let splash = new SplashScreen(true, "Logging you out...")

        // Expire session token
        // tokenManager.removeEventListener("firebase_logout", this.firebaseLogoutHandler)

        let cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            let cookie = cookies[i];
            let eqPos = cookie.indexOf("=");
            let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
        }

        tokenManager.signOut().then().catch().finally(async () => {
            // TRIGGER STEP 2:
            try {
                if (WORKER) await WORKER.messageSW({
                    type: "RESET"
                })
            } catch(e) {
                console.log("Failed to send reset message to service worker")
                console.error(e)
            }

            // Redirect to the login page
            localStorage.clear()
            // let reg = await navigator.serviceWorker.getRegistration('/serviceWorker.js')

            // Close database connection and redirect to logout page
            // this.DBClass.indexedDB.commit()
            await Database.events.onLogout()

            Database.close()
            setTimeout(() => {
                window.location.href = "/pages/logout/index.min.html"
            }, 2000)
        })
    }
}

export const AccountManager = new AccountManagerClass()