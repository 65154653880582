import {FarmData} from "../../models/DatabaseDataTypes";
import {Database} from "../services/DBClass";
import {TABLES} from "../../models/TABLES";
import {DatabaseBatchOperation} from "../databaseBatchOperation";
import {Report} from "./Tests/report";

export class Farm {
    data: FarmData

    static fromArray(data: FarmData[]) {return data.map(i => new Farm(i))}
    static from(data: FarmData) {return new Farm(data)}

    constructor(data: FarmData) {
        this.data = data
    }

    async supplier() {
        return (await Database.get(TABLES.suppliers, this.data.milkSuppliedTo))
    }

    async snapshotReports() {
        return (await Database.getByIndex(TABLES.tests, "farm_id", IDBKeyRange.only(this.data.uuid)))
            .map(record => new Report(record))
    }

    async save(batch?: DatabaseBatchOperation) {
        if (batch) batch.put(TABLES.farms, this.data, (err) => {
        })
        else {
            await Database.put(TABLES.farms, this.data)
        }
        return
    }

    async delete(batch?: DatabaseBatchOperation, triggerEvent = true) {
        // Trigger delete event
        if (triggerEvent) {
            // this.emit(EVENTS.onReportDeleted)
        }

        let commit_batch = !batch
        if (!batch) batch = Database.batchOperation()
        // If no batch operation was passed in, create a batch operation and commit it at the end

        for (let section of await this.snapshotReports()) await section.delete(batch)

        batch.delete(TABLES.tests, this.data.uuid)
        if (commit_batch) await batch.commit()
    }
}