import {DBImage} from "../utils/DatabaseData/Tests/DBImage";
import {ArchiveData} from "../models/DatabaseDataTypes";

export class DBImageElement extends DBImage {
    private div = document.createElement("div")

    constructor(imageData: ArchiveData) {
        super(imageData);
    }

    async load(div?: HTMLDivElement) {
        if (div) this.div = div
        this.div.style.backgroundImage = "url(" + URL.createObjectURL((await this.get()).data) + ")"
        this.div.classList.add("image_upload_item")
        this.div.onclick = (e) => {
            this.expandView()
        }
        super.load()
        return this.div
    }


    async expandView() {
        let img_mgr_wrapper = document.getElementById("img_mgr_wrapper") as HTMLDivElement
        img_mgr_wrapper.style.display = "block"

        let img_mgr_preview = <HTMLImageElement>document.getElementById("img_mgr_preview")
        img_mgr_preview.src = URL.createObjectURL((await this.get()).data)

        let img_mgr_del = document.getElementById("img_mgr_del") as HTMLDivElement
        img_mgr_del.onclick = (e) => {
            this.delete()

            // Hide manager and remove this.div from DOM
            img_mgr_wrapper.style.display = ''
            try {
                this.div.parentElement?.removeChild(this.div)
            } catch (e) {
            }
        }
    }
}