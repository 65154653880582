import {Notification} from "./notification";
import {GlobalEventsService} from "../utils/services/events/globalEventsService";

export class NotificationErr extends Notification {
    static thisTab(iconHTML: string, html: string, autoDismiss = true) {
        return new this(iconHTML, html, autoDismiss)
    }

    static broadcast(iconHTML: string, html: string, autoDismiss = true) {
        GlobalEventsService.emit("onInAppNotification", {
            details: [{
                type: "error",
                iconHTML, html, autoDismiss
            }]
        })
    }

    protected constructor(iconHTML: string, html: string, autoDismiss = true) {
        super(iconHTML, html, autoDismiss)
        this.div.classList.add("notification_err")
    }
}

// Only register the event on JS instances that have access to a DOM.
if (self.document) GlobalEventsService.on("onInAppNotification", (notification) => {
    if (notification.details[0].type !== "error") return
    NotificationErr.thisTab(notification.details[0].iconHTML, notification.details[0].html, notification.details[0].autoDismiss)
}, null)