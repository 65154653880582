export function downloadBlob(method: "get" | "post" | "patch", url: string, token: string) {
    if (!navigator.onLine) throw new Error("Client is not online!")
    return new Promise<Blob>((resolve, reject) => {
        let controller = new AbortController();
        // setTimeout(() => {
        //     controller.abort("Took too long to download")
        // }, 60000)

        fetch(url, {
            method: "get",
            headers: {Authorization: "Bearer " + token},
            signal: controller.signal
        })
            .then(res => res.blob())
            .then(blob => resolve(blob))
            .catch(e => reject(e))
    })
}