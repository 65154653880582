import {getMultiFactorResolver, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier} from "firebase/auth";

export async function resolveOTP(e: any, userAuth: any) {
    //prepare recaptcha
    let captcha_box = document.createElement("div");
    captcha_box.setAttribute("id", "recaptcha-container-id");
    document.body.appendChild(captcha_box);
    let overlay = document.createElement("div");

    let recaptchaVerifier = new RecaptchaVerifier(userAuth, "recaptcha-container-id", {
        "size": "invisible",
    });

    //prepare resolver
    const resolver = getMultiFactorResolver(userAuth, e);
    // Ask user which second factor to use.
    if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session
        };
        const phoneAuthProvider = new PhoneAuthProvider(userAuth);
        // Send SMS verification code
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
            .then(function (verificationId) {

                return new Promise<string>((resolve, reject) => {

                    overlay.classList.add("overlay");
                    overlay.setAttribute("id", "popup_overlay");
                    overlay.style.display = "block";
                    overlay.innerHTML = "<div class=\"popup\"><div id=\"close_popup\" class=\"close_otp\">&#10006;</div><form id=\"otp_form\"><p>Enter 6-digit code sent to your mobile number</p><p>&nbsp;</p><input type=\"text\" aria-placeholder=\"Enter the 6-digit code sent in your mobile phone\" id=\"otp_code\" value=\"\" class=\"login_field\"><button id=\"otp_btn\" class=\"primary_button\">Submit Verification Code</button></form></div>";

                    overlay.style.display = "block";
                    document.body.appendChild(overlay);

                    (document.getElementById("close_popup") as HTMLDivElement).addEventListener("click", (e) => {
                        e.preventDefault()
                        recaptchaVerifier.clear()
                        overlay.remove()
                        captcha_box.remove()
                    });

                    (document.getElementById("otp_form") as HTMLDivElement).addEventListener("submit", (e) => {
                        e.preventDefault();
                        // @ts-expect-error
                        let otp_code = e.target[0].value;
                        //console.log("user entered otp and submitted form")
                        resolve(otp_code)
                        //overlay.remove();
                        console.log('entered verification code')
                        console.log(otp_code)
                    });
                }).then((verificationCode) => {
                    // Ask user for the SMS verification code. Then:
                    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
                    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
                    // Complete sign-in.
                    return resolver.resolveSignIn(multiFactorAssertion)

                }).catch(err => {
                    if (err.code == "auth/invalid-verification-code") {
                        // reject(e.code)
                        recaptchaVerifier.clear()
                        captcha_box.remove();
                        (document.getElementById("otp_btn") as HTMLDivElement).innerHTML = "Incorrect Verification Code. Please try again.";
                        (document.getElementById("otp_btn") as HTMLDivElement).classList.add("error_button");
                        //if 2fa fails, inform user and reload page
                        setTimeout(() => {
                            //window.location.reload()
                            (document.getElementById("otp_btn") as HTMLDivElement).innerHTML = "Submit Verification Code";
                            (document.getElementById("otp_btn") as HTMLDivElement).classList.remove("error_button");
                            window.location.reload()
                        }, 3000)
                    }

                    console.log(err)
                })
            })
            .then(function (userCredential) {
                // User successfully signed in with the second factor phone number.
                recaptchaVerifier.clear()
                if (userCredential) {
                    captcha_box.remove();
                    overlay.remove()
                    return 1
                }

            }).catch((e) => {
                recaptchaVerifier.clear()
                console.log(e)
                return e.code
            })
    }
    else {
        //using other mode
        console.log('using other mode')
    }
}