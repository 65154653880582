import {SENTRY} from "./SENTRY";
import {Database} from "./services/DBClass";
import {DatabaseBatchOperation} from "./databaseBatchOperation";

export interface QueueItemCompleteEvent extends Event {
    detail: {
        overallQueueLength: number,
        currentQueueLength: number,
        activeSubQueues: number
    }
}

type QueueItem = [string, (...args: any[]) => Promise<void>]

export class ActionQueue {
    public items: QueueItem[];
    private subqueues: subqueue[];
    public queueLength: number;
    private promise: Promise<Awaited<unknown>[]> | null = null;
    dbBatch = Database.batchOperation()

    onItemComplete = (totalLength: number, currentLength: number, activeQueues: number) => {}
    onItemError = (error: unknown, item: QueueItem) => {
        console.error("A queue item failed", item[0], item)
        console.error(error)
        SENTRY.captureException(error)
    }

    autostart: boolean;

    constructor(queueCount = 4, autostart = false) {
        this.items = []
        this.subqueues = []
        for (let i = 0; i < queueCount; i++) this.subqueues.push(new subqueue(this))
        this.queueLength = 0
        this.autostart = autostart
    }

    pushToQueue<F extends (batch: DatabaseBatchOperation) => Promise<void>>(description: string, func: F) {
        this.items.push([description, func])
        this.queueLength += 1
        if (!this.promise && this.autostart) this.start()
    }

    emitItemComplete() {
        this.onItemComplete(this.queueLength, this.items.length, this.subqueues.filter(queue => queue.running).length)
    }

    async start() {
        if (this.promise) {
            await this.promise
            return
        }

        console.log(`STARTING QUEUE WITH ${this.items.length} JOBS`)
        this.promise = Promise.all(this.subqueues.map(queue => queue.start()))
        await this.promise
        await this.dbBatch.commit()
        this.promise = null
    }

    get [Symbol.toStringTag]() {return this.constructor.name}
}

class subqueue {
    private parentQueue: ActionQueue
    public running: boolean

    constructor(parentQueue: ActionQueue) {
        this.parentQueue = parentQueue
        this.running = false
    }

    async start() {
        this.running = true
        while (this.parentQueue.items.length > 0) {
            let current_item = this.parentQueue.items.splice(0, 1)[0]
            try {
                await current_item[1](this.parentQueue.dbBatch)
            } catch (e) {
                this.parentQueue.onItemError(e, current_item)
            }
            if (this.parentQueue.items.length > 0) this.parentQueue.emitItemComplete()
        }
        console.log("ENDING SUBQUEUE...")
        this.running = false
        // this.parentQueue.emitItemComplete()
    }
}

export class ActionQueueChain {
    private queues: ActionQueue[] = []
    private promise: Promise<Awaited<unknown>[]> | null = null;
    private totalItems: number = 0
    private completedItems = 0
    onItemComplete = (totalLength: number, currentLength: number, activeQueues: number) => {}
    onItemError = (error: unknown, item: QueueItem) => {
        console.error("A queue item failed", item[0], item)
        console.error(error)
        SENTRY.captureException(error)
    }

    constructor(queues?: ActionQueue[]) {
        if (queues) for (let queue of queues) this.addToChain(queue)
    }

    async start() {
        this.totalItems = this.queues.reduce((count, queue) => count + queue.items.length, 0)
        for (let queue of this.queues) await queue.start()
    }

    addToChain(queue: ActionQueue) {
        queue.onItemComplete = (totalLength, currentLength, activeQueues) => {
            this.completedItems += 1
            this.onItemComplete(this.totalItems, this.completedItems, activeQueues)
        }
        queue.onItemError = this.onItemError
        this.queues.push(queue)
    }
}