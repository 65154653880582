import {version} from "../utils/UpdateControl/version";

export class SplashScreen {
    protected readonly div: HTMLElement
    protected readonly content: HTMLElement
    protected readonly logo: HTMLImageElement
    protected readonly versionNum: HTMLElement
    protected readonly text: HTMLElement | null = null

    constructor(show_fade_in = false, text = "", html = false) {
        this.div = document.createElement("div")
        this.div.classList.add('splashscreen_wrapper')

        this.content = document.createElement("div")
        this.content.classList.add("splashscreen_content")

        this.logo = document.createElement("img")
        this.logo.src = "/assets/images/snapshot_icon.png"
        this.logo.classList.add("splashscreen_logo")
        this.versionNum = document.createElement("div")
        this.versionNum.innerText = version.toString()
        this.versionNum.classList.add("splashscreen_versionnum")
        this.content.appendChild(this.logo)

        if (text !== "") {
            this.text = document.createElement("div")
            if (html) {
                this.text.innerHTML = text
            }
            else {
                this.text.innerText = text
            }
            this.content.appendChild(this.text)
        }

        this.div.appendChild(this.content)
        this.div.appendChild(this.versionNum)

        if (show_fade_in) {
            this.div.style.animation = "splashScreenFadeIn 1s";
        }
        document.body.appendChild(this.div)
    }

    delete() {
        this.div.style.animation = "splashScreenFadeOut 1s";
        setTimeout(() => {
            document.body.removeChild(this.div)
        }, 1000)
    }
}