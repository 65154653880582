// This class needs to be exported as some files use it to create child classes
import {DBImage} from "./DBImage";
import {DBImageElement} from "../../../components/DBImageElement";
import {Database} from "../../services/DBClass";
import {FieldData} from "../../../models/DatabaseDataTypes";
import {DatabaseBatchOperation} from "../../databaseBatchOperation";
import {TABLES} from "../../../models/TABLES";

export class Field {
    get [Symbol.toStringTag]() {return this.constructor.name}
    data: FieldData
    private _saveData: FieldData
    uuid: string
    saving: boolean = false

    static async getField(field_id: string) {
        let data = await Database.get(TABLES.fields, field_id)
        return data ? new Field(data.uuid, data) : null
    }

    constructor(uuid: string, data: FieldData) {
        // Append fields
        this._saveData = data
        const setSaveData = (data: FieldData) => this._saveData = data

        this.data = new Proxy(data, {
            get(target: FieldData, p: keyof FieldData, receiver: any): any {
                return target[p]
            },
            set(target: FieldData, p: string | symbol, newValue: any, receiver: any): boolean {
                if (p === "content" && !newValue && newValue !== "") {
                    console.error(new Error("A script attempted to set the content of a field to: " + newValue))
                    newValue = ""
                }
                // @ts-expect-error
                target[p] = newValue
                setSaveData(target)
                return true
            }
        })

        this.uuid = uuid
    }

    get(): Promise<FieldData> {
        return (Database.get(TABLES.fields, this.uuid)
            .then(e => {
                this.data = e
                return e
            }))
    }

    async delete(batch?: DatabaseBatchOperation) {
        let commit_batch = !batch
        if (!batch) batch = Database.batchOperation()
        // If no batch operation was passed in, create a batch operation and commit it at the end

        if ((await this.get()).type === "image_upload") {
            for (let image of await this.getImages()) await image.delete(batch)
        }

        batch.delete(TABLES.fields, this.uuid)
        if (commit_batch) await batch.commit()
    }

    getImages(sampleClass?: string, getAsElements?: false, include_deleted?: boolean): Promise<DBImage[]>
    getImages(sampleClass?: string, getAsElements?: true, include_deleted?: boolean): Promise<DBImageElement[]>
    async getImages(sampleClass = "", getAsElements = false, include_deleted?: boolean): Promise<DBImage[] | DBImageElement[]> {
        let images = await Database.search(TABLES.archive, (record) => {
            return record.sampleClass === this.uuid
        })
        return images.map(image => {
            return getAsElements ? new DBImageElement(image) : new DBImage(image)
        })
    }

    unattachListeners() {
        // this.DBClass.reports.removeEventListener("section_update", this.eventHandlers.section_update)
        // this.DBClass.reports.removeEventListener("header_update", this.eventHandlers.header_update)
        // this.DBClass.reports.removeEventListener("field_update", this.eventHandlers.field_update)
        // this.DBClass.reports.removeEventListener("field_id_changed", this.eventHandlers.field_id_changed)
    }

    save(batch?: DatabaseBatchOperation) {
        // Check for any fields that reference this one in their validation or condition
        this.saving = true;

        (batch || Database).put(TABLES.fields, this._saveData)
        // SaveManager.addToQueue(this.data, () => {
        //     this.saving = false
        // })
    }
}