/*
Manages all suppliers in the connected local database
WARNING: `new supplierManager()` should ONLY ever be called by an instance of DBClass. You can access a DBClass's supplier
manager via SnapshotAPI.SupplierManager.
*/
import {Supplier} from "../supplier";
import {Database} from "./DBClass";
import {ManagerBaseClass} from "./ManagerBaseClass";
import {TABLES} from "../../models/TABLES";
import {SupplierData} from "../../models/DatabaseDataTypes";

export class SupplierManagerClass extends ManagerBaseClass {
    get [Symbol.toStringTag]() {return this.constructor.name}
    constructor() {
        super()
    }

    async addAll(array: SupplierData[]) {
        let batch = Database.batchOperation()
        for (let item of array) {
            batch.put(TABLES.suppliers, item)
        }
        await batch.commit()
    }

    async getAll() {
        let data = await Database.getAll(TABLES.suppliers)
        return data.map(i =>new Supplier(i))
    }

    async get(supplier_id: string): Promise<Supplier> {
        let data = await Database.get(TABLES.suppliers, supplier_id)
        return new Supplier(data)
    }

    async delete(supplier_id: string) {
        await Database.delete(TABLES.suppliers, supplier_id)
    }

    async put(supplier_data: SupplierData) {
        await Database.put(TABLES.suppliers, supplier_data)
    }
}

export const SupplierManager = new SupplierManagerClass()