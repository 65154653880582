/*
DBClass is the primary class you'll want to use for most (if not all) interactions with indexedDB.

It'll allow you to easily connect to indexedDB, and perform common tasks with ease.
*/
import {ManagerBaseClass} from "./ManagerBaseClass";
import JSZip from "jszip";
import {NotificationErr} from "../../components/notificationErr";
import {SENTRY} from "../SENTRY";
import {makeid} from "../utilities";
import {DatabaseBatchOperation} from "../databaseBatchOperation";
import {GlobalEventsService} from "./events/globalEventsService";
import {TABLES, TableStructure} from "../../models/TABLES";
import {BaseUpgradeKit} from "./databaseUpgradeKits/baseUpgradeKit";
import {CreateNewDatabase} from "./databaseUpgradeKits/CreateNewDatabase";
import {Upgrade8to10} from "./databaseUpgradeKits/Upgrade8to10";
import {Upgrade10to11} from "./databaseUpgradeKits/Upgrade10to11";
import {Upgrade11to12} from "./databaseUpgradeKits/Upgrade11to12";
import {Upgrade9to10} from "./databaseUpgradeKits/Upgrade9to10";
import {Upgrade12to13} from "./databaseUpgradeKits/Upgrade12to13";
import {Upgrade13to15} from "./databaseUpgradeKits/Upgrade13to15";
import {Upgrade14to15} from "./databaseUpgradeKits/Upgrade14to15";
import {Upgrade15to16} from "./databaseUpgradeKits/Upgrade15to16";
import {Upgrade16to17} from "./databaseUpgradeKits/Upgrade16to17";

interface FailsafeOutputs {
    base64: string;
    string: string;
    text: string;
    binarystring: string;
    array: number[];
    uint8array: Uint8Array;
    arraybuffer: ArrayBuffer;
    blob: Blob;
    nodebuffer: Buffer;
}

export class DBClass extends ManagerBaseClass {
    private readonly databaseUpgrades: BaseUpgradeKit[] = [
        new CreateNewDatabase(),
        new Upgrade8to10(),
        new Upgrade9to10(),
        new Upgrade10to11(),
        new Upgrade11to12(),
        new Upgrade12to13(),
        new Upgrade13to15(),
        new Upgrade14to15(),
        new Upgrade15to16(),
        new Upgrade16to17()
    ]
    request: IDBOpenDBRequest | null = null;

    get [Symbol.toStringTag]() {
        return this.constructor.name
    }

    connected: boolean
    events: any
    // readonly account: AccountManager;
    // readonly reports: reportManager;
    // readonly farms: FarmManager;
    // readonly suppliers: SupplierManager;
    // readonly session: SessionManager;
    // readonly archive: ArchiveManager;
    private _database: IDBDatabase | null = null;
    private _windowReloadOnFocus: boolean = false;
    
    constructor() {
        super();
        // Check for updates
        this.connected = false
        this.events = {
            dbSuccess: () => {
            },
            dbError: () => {
            },
            dbUpgrade: () => {
            },
            dbBlocked: () => {
            },
            onLogout: () => {
                return new Promise<void>(resolve => {
                    resolve()
                })
            }
        }
        this.events._dbSuccess = () => {
            this.connected = true;
            this.events.dbSuccess();
        }

        // this.account = new AccountManager(this)
        // this.reports = new reportManager(this)
        // this.farms = new FarmManager(this)
        // this.suppliers = new SupplierManager(this)
        // this.session = new SessionManager(this)
        // this.archive = new ArchiveManager(this)
    }
    
    private async database() {
        if (!this._database) this._database = await this._createDB()
        return this._database
    }

    _createDB(connectionAttempt = 0): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            console.log("Opening database")
            // if (CSS.supports("( -webkit-box-reflect:unset )")) {
            //     let splash = new SplashScreen(false, safari_splash_text)
            //     await wait(3000)
            //     splash.delete()
            // }

            let openDBRequest = indexedDB.open("snapshot", 17)

            openDBRequest.onsuccess = (e) => {
                let database = (<IDBRequest>e.target).result
                this.connected = true
                this.events._dbSuccess()

                database.addEventListener("error", (e: ErrorEvent) => {
                    SENTRY.captureException(e.error)
                })

                database.addEventListener("close", (e: Event) => {
                    console.warn("IndexedDB closed unexpectedly. Will attempt to reconnect...")
                    // Attempt to reconnect to the database
                    this._createDB()
                        .then(() => {
                        })
                        .catch(e => {
                            SENTRY.captureEvent(new Error("IndexedDB closed unexpectedly. Failed to re-open"), {originalException: e})
                            NotificationErr.broadcast("<i class=\"fa-solid fa-database\"></i>", "An internal storage error occurred. To prevent data loss, it is recommended that you either refresh the page, or quit and re-open the app.")
                            // Attempt to re-open indexedDB after 500ms
                            setTimeout(() => {
                                // window.location.reload()
                            }, 3000)
                        })
                })

                self.window?.addEventListener("focus", (e) => {
                    if (this._windowReloadOnFocus) window.location.reload()
                })

                self.window?.addEventListener("unload", () => {
                    database.close()
                })

                // Listen to the "disconnectDB" broadcast channel, incase a disconenct is ever required
                let channel = new BroadcastChannel("disconnectDB")
                channel.addEventListener("message", (msg) => {
                    // After 1 minute, disconnect database and require page refresh when user next clicks on page
                    database.close()
                    this._windowReloadOnFocus = true
                })

                console.log("Resolving with open database...")
                resolve(database)
            }

            openDBRequest.onerror = (e) => {
                console.error(openDBRequest.error)
                SENTRY.captureException(openDBRequest.error)
                this.events.dbError()

                if (connectionAttempt === 5) {
                    // 6 attempts to connect to the database have already failed,
                    reject(openDBRequest.error)
                }
                else {
                    // Attempt to connect again
                    this._createDB(connectionAttempt + 1).then((db) => resolve(db)).catch(e => reject(e))
                }
            }

            openDBRequest.onupgradeneeded = (e) => {
                if (!e.newVersion) {
                    console.log("Database deleting...")
                    return
                }

                // Find an upgrade path using the available upgrade kits
                const db = openDBRequest.result
                const transaction = openDBRequest.transaction as IDBTransaction
                let kits = findUpgradePath(
                    e.oldVersion,
                    e.newVersion,
                    this.databaseUpgrades.sort((a, b) => {
                        let [a_size, b_size] = [a.newVersion - a.oldVersion, b.newVersion - b.oldVersion]
                        return a_size > b_size ? -1 : 1
                    })
                )
                if (!kits) {
                    console.warn(`Could not find upgrade path for v${e.oldVersion} to v${e.newVersion}`)
                    throw new Error("No database upgrade path available")
                }
                console.log("Upgrading database using these kits", kits)

                // Run the upgrade kits
                for (let kit of kits) {
                    // Create any new tables
                    for (let table of kit.tables) if (!db.objectStoreNames.contains(table[0])) db.createObjectStore(table[0], table[1])

                    // Rebuild indexes
                    console.log(kit)
                    for (let index of kit.indexes) {
                        let store = transaction.objectStore(index.table)
                        if (store.indexNames.contains(index.name)) store.deleteIndex(index.name)
                        store.createIndex(index.name, index.keyPath, index.options)
                    }

                    // Run data transformations
                    try {
                        kit.performDataTransformation(transaction)
                    } catch (e) {
                        console.error(e)
                    }
                }
            }

            openDBRequest.onblocked = (e) => {
                SENTRY.captureException(new Error("IndexedDB database open request blocked"))

                let event = new Event("dbBlocked")
                this.events.dbBlocked()
            }
            this.request = openDBRequest
        })
    }

    put<T extends TABLES>(table: T, data: TableStructure[T]): Promise<IDBRequest<IDBValidKey>> {
        return new Promise((resolve, reject) => {
            this.database().then(db => {
                let transaction = db.transaction(table, "readwrite")
                let req = transaction.objectStore(table).put(data)
                req.onsuccess = (e) => {
                    GlobalEventsService.emit("onIndexedDBModified", {details: [{table, data}]})
                    resolve(req)
                }
                req.onerror = (e) => {
                    reject(req.error)
                }
                transaction.commit()
            })
        })
    }

    get<T extends TABLES>(table: T, id: string): Promise<TableStructure[T]> {
        return new Promise((resolve, reject) => {
            this.database().then(db => {
                const transaction = db.transaction(table, "readonly").objectStore(table).get(id)
                let timer = setTimeout(() => {
                    NotificationErr.broadcast("<i class=\"fa-regular fa-hourglass-clock\"></i>", "Your browser isn't responding to our request to read data. We recommend that you close and re-open the web app.", false)
                }, 5000)
                transaction.onsuccess = (e) => {
                    clearTimeout(timer)
                    resolve(transaction.result)
                }
                transaction.onerror = (e => {
                    clearTimeout(timer)
                    reject(e)
                })
            })
        })
    }

    delete(table: TABLES, id: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.database().then(db => {
                let get_req = db.transaction(table, "readwrite").objectStore(table).delete(id)
                let timer = setTimeout(() => {
                    NotificationErr.broadcast("<i class=\"fa-regular fa-hourglass-clock\"></i>", "Your browser isn't responding to our request to read data. We recommend that you close and re-open the web app.", false)
                }, 5000)
                get_req.onsuccess = (e) => {
                    GlobalEventsService.emit("onIndexedDBDeleted", {details: [{table, id}]})
                    clearTimeout(timer)
                    resolve()
                }
                get_req.onerror = (e => {
                    clearTimeout(timer)
                    reject(e)
                })
            })
        })
    }

    getAll<T extends TABLES>(table: T): Promise<TableStructure[T][]> {
        return new Promise((resolve, reject) => {
            this.database().then(db => {
                let trans = db.transaction(table, "readonly")
                let req = trans.objectStore(table).getAll()
                req.onsuccess = (e) => {
                    resolve(req.result)
                }
                req.onerror = (e) => {
                    reject(req.error)
                }
                trans.commit()
            })
        })
    }

    search<T extends TABLES>(table: T, filter: (record: TableStructure[T]) => boolean): Promise<TableStructure[T][]> {
        return new Promise((resolve, reject) => {
            let res: TableStructure[T][] = []

            this.database().then(db => {
                let trans = db.transaction(table, "readonly")
                let cursor = trans.objectStore(table).openCursor()
                cursor.onsuccess = (e) => {
                    if (cursor.result) {
                        if (filter(cursor.result.value)) res.push(cursor.result.value)
                        cursor.result.continue()
                    }
                    else {
                        trans.commit()
                        resolve(res)
                    }
                }
                cursor.onerror = (e) => {
                    reject(cursor.error)
                }
            })
        })
    }

    getByIndex<T extends TABLES>(table: T, indexName: string, key: IDBValidKey | IDBKeyRange | null = null): Promise<TableStructure[T][]> {
        return new Promise((resolve, reject) => {
            let res: TableStructure[T][] = []

            this.database().then(db => {
                const trans = db.transaction(table, "readonly")
                const index = trans.objectStore(table).index(indexName)
                const cursor = index.openCursor(key)
                cursor.onsuccess = (e) => {
                    if (cursor.result) {
                        res.push(cursor.result.value)
                        cursor.result.continue()
                    }
                    else {
                        trans.commit()
                        resolve(res)
                    }
                }
                cursor.onerror = (e) => {
                    reject(cursor.error)
                }
                // trans.commit()
            })
        })
    }

    writeByIndex<T extends TABLES>(
        table: T,
        indexName: string,
        key: IDBValidKey | IDBKeyRange | null = null,
        callback: (cursor: Omit<IDBCursorWithValue, "continue">) => void
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            this.database().then(db => {
                const trans = db.transaction(table, "readwrite")
                const index = trans.objectStore(table).index(indexName)
                const cursor = index.openCursor(key)
                cursor.onsuccess = (e) => {
                    if (cursor.result) {
                        callback(cursor.result)
                        cursor.result.continue()
                    }
                    else {
                        trans.commit()
                        resolve()
                    }
                }
                cursor.onerror = (e) => {
                    reject(cursor.error)
                }
                // trans.commit()
            })
        })
    }

    close() {
        this.database().then(db => db.close())
    }

    batchOperation() {
        return new DatabaseBatchOperation(this.database())
    }
    async waitForConnection() {
        await this.database()
    }

    async generateFailSafeFile<T extends keyof FailsafeOutputs>(): Promise<Blob> {
        if (!JSZip) {
            alert("Please reload the page and try again")
            throw new Error("Cannot find JSZip")
        }

        let db = await this.database()
        let zip = new JSZip()
        zip.file("INDEXEDDB.json", JSON.stringify({
            name: db.name,
            objectStoreNames: Array.from(db.objectStoreNames),
            version: db.version
        }))
        let data_folder = zip.folder("data")
        if (!data_folder) throw new Error("Failed to generate ZIP")
        data_folder.file("account.json", JSON.stringify(await this.getAll(TABLES.account)))
        data_folder.file("farms.json", JSON.stringify(await this.getAll(TABLES.farms)))
        data_folder.file("fields.json", JSON.stringify(await this.getAll(TABLES.fields)))
        data_folder.file("headers.json", JSON.stringify(await this.getAll(TABLES.headers)))
        data_folder.file("sections.json", JSON.stringify(await this.getAll(TABLES.sections)))
        data_folder.file("suppliers.json", JSON.stringify(await this.getAll(TABLES.suppliers)))
        data_folder.file("tests.json", JSON.stringify(await this.getAll(TABLES.tests)))
        let images = await this.getAll(TABLES.archive)
        let image_folder = data_folder.folder("archive")
        if (!image_folder) throw new Error("Failed to generate ZIP")

        for (let i in images) {
            let filename = makeid(10) + ".jpg"
            image_folder.file(filename, images[i].data)
            // @ts-expect-error
            delete images[i].data
            // @ts-ignore
            images[i].filename = filename
        }
        data_folder.file("archive.json", JSON.stringify(images))
        return await zip.generateAsync({type: "blob"})
    }

    async generateFailsafe() {
        // TODO: Patch this method
        let url = URL.createObjectURL(await this.generateFailSafeFile())
        let a = document.createElement("a")
        a.download = "snapshot_failsafe.zip"
        a.target = "_blank"
        a.href = url
        a.click();
        URL.revokeObjectURL(url)
        // saveAs(await this.generateFailSafeFile(), "snapshot_failsafe.zip")
    }
}

export const Database: DBClass = new DBClass()

function findUpgradePath(oldVersion: number, newVersion: number, upgradeKits: BaseUpgradeKit[]): BaseUpgradeKit[] | null {
    let filtered_kits = upgradeKits.filter(kit => kit.oldVersion === oldVersion)
    for (let kit of filtered_kits) {
        if (kit.newVersion === newVersion) return [kit]
        else {
            let higherLevelKits = findUpgradePath(kit.newVersion, newVersion, upgradeKits)
            if (higherLevelKits !== null) return [kit, ...higherLevelKits]
        }
    }
    return null
}