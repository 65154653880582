import {GlobalEventsService} from "./services/events/globalEventsService";
import {TABLES, TableStructure} from "../models/TABLES";

interface DatabaseModifyAction {
    type: "add" | "put",
    data: any,
    ondone: (err: DOMException | null) => void
}

interface DatabaseKeyAction {
    type: "delete",
    key: string,
    ondone: (err: DOMException | null) => void
}

export class DatabaseBatchOperation {
    private readonly database: Promise<IDBDatabase>
    private readonly actions: Map<string, (DatabaseModifyAction | DatabaseKeyAction)[]> = new Map()

    constructor(database: Promise<IDBDatabase>) {
        this.database = database
    }

    add<T extends TABLES>(table: T, data: TableStructure[T], ondone?: (err: DOMException | null) => void) {
        let table_actions = this.actions.get(table) || []
        table_actions.push({
            type: "add",
            data,
            ondone: (err: DOMException | null) => {
                if (!err) GlobalEventsService.emit("onIndexedDBModified", {details: [{table, data}]})
                if (ondone) ondone(err)
                else if (err) console.error(err)
            }
        })
        this.actions.set(table, table_actions)
    }

    put<T extends TABLES>(table: T, data: TableStructure[T], ondone?: (err: DOMException | null) => void) {
        let table_actions = this.actions.get(table) || []
        table_actions.push({
            type: "put",
            data,
            ondone: (err: DOMException | null) => {
                if (!err) GlobalEventsService.emit("onIndexedDBModified", {details: [{table, data}]})
                if (ondone) ondone(err)
                else if (err) console.error(err)
            }
        })
        this.actions.set(table, table_actions)
    }

    delete(table: TABLES, key: string, ondone?: (err: DOMException | null) => void) {
        let table_actions = this.actions.get(table) || []
        table_actions.push({
            type: "delete",
            key,
            ondone: (err: DOMException | null) => {
                if (!err) GlobalEventsService.emit("onIndexedDBDeleted", {details: [{table, id: key}]})
                if (ondone) ondone(err)
                else if (err) console.error(err)
            }
        })
        this.actions.set(table, table_actions)
    }

    async commit() {
        // Open transactions and commit all changes
        let transactionCommits: Promise<void>[] = []
        const database = await this.database
        for (let table of this.actions) {
            let transaction = database.transaction(table[0], "readwrite")
            let objectStore = transaction.objectStore(table[0])
            for (let action of table[1]) {
                let req: IDBRequest = objectStore[action.type](action.type === "delete" ? action.key : action.data)
                
                req.onsuccess = () => {action.ondone(null)}
                req.onerror = () => {
                    action.ondone(req.error)
                }
            }

            transactionCommits.push(asyncTransactionCommitNoError(transaction, table[0]))
        }
        try {
            await Promise.all(transactionCommits)
        } catch(e) {
            console.log(Array.from(this.actions))
            console.error("DATABASE BATCH OPERATION FAILED")
            throw e
        }
        this.actions.clear()
        return
    }
}

function asyncTransactionCommitNoError(transaction: IDBTransaction, tableName: string): Promise<void> {
    return new Promise((resolve, reject) => {
        transaction.oncomplete = () => {resolve()}
        transaction.onerror = () => {
            console.error("OPERATION FAILED ON TABLE: " + tableName)
            reject(transaction.error)
        }
        transaction.commit()
    })
}