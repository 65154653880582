
export interface BasicEventDetails {
    details: any[],
    extraData?: any
}

export interface BasicEventsList {
    [key: string]: BasicEventDetails
}

export interface EventHandler<ARGS extends any[], EXTRA_DATA = any> {
    type: "once" | "on"
    extraData: EXTRA_DATA,

    method(...args: ARGS): void
}


export abstract class BaseEventsService<
    /*
    Extra data that can be attached to each event. Useful for filtering.
     */
    EVENT_EXTRA_DATA extends any,
    /*
    A type containing all the possible events, and the associated data
     */
    EVENT_DETAILS extends BasicEventsList,
> {
    private eventHandlers = new Map<keyof EVENT_DETAILS, EventHandler<any[], EVENT_EXTRA_DATA>[]>()
    readonly channelName: string = "default_channel"
    private channel = new BroadcastChannel(this.channelName);

    constructor() {
        this.channel.onmessage = (e) => this.onChannelMessage(e)
    }

    private onChannelMessage<T extends keyof EVENT_DETAILS>(e: MessageEvent<{ event: T, eventData: EVENT_DETAILS[T] }>) {
        this.messageReceiver(e.data)
    }

    private messageReceiver<T extends keyof EVENT_DETAILS>(e: { event: T, eventData: EVENT_DETAILS[T] }) {
        let handlers: EventHandler<EVENT_DETAILS[T]["details"]>[] = this.eventHandlers.get(e.event) || []

        // Filter handlers
        handlers = handlers.filter(handler => this.eventFilter(e, handler))

        for (let handler of handlers) handler.method({...e.eventData, event: e.event})
    }

    protected eventFilter<T extends keyof EVENT_DETAILS>
    (message: {
        event: T,
        eventData: EVENT_DETAILS[T]
    }, handler: EventHandler<EVENT_DETAILS[T]["details"], EVENT_EXTRA_DATA>) {
        return true
    }

    emit<T extends keyof EVENT_DETAILS>(event: T, eventData: EVENT_DETAILS[T]) {
        const message: { event: T, eventData: EVENT_DETAILS[T] } = {event, eventData}
        this.channel.postMessage(message)
        this.messageReceiver(message)
    }

    addEventListener<T extends keyof EVENT_DETAILS>
    (type: "on" | "once", event: T, extraData: EVENT_EXTRA_DATA, method: (event: EVENT_DETAILS[T] & {event: T}) => void) {
        let event_array: EventHandler<EVENT_DETAILS[T]["details"], EVENT_EXTRA_DATA>[] = this.eventHandlers.get(event) || []
        event_array.push({type, method, extraData})
        this.eventHandlers.set(event, event_array)
    }

    removeEventListener<T extends keyof EVENT_DETAILS>
    (event: T, method: (event: EVENT_DETAILS[T] & {event: T}) => void) {
        let event_array = this.eventHandlers.get(event) || []
        // @ts-expect-error
        let index = event_array.indexOf(event_array.find(i => i.method === method))
        if (index !== -1) event_array.splice(
            index,
            1
        )
        this.eventHandlers.set(event, event_array)
    }

    on<T extends keyof EVENT_DETAILS>(event: T, method: (event: EVENT_DETAILS[T] & {event: T}) => void, extraData: EVENT_EXTRA_DATA) {
        return this.addEventListener("on", event, extraData, method)
    }

    once<T extends keyof EVENT_DETAILS>(event: T, method: (event: EVENT_DETAILS[T] & {event: T}) => void, extraData: EVENT_EXTRA_DATA) {
        return this.addEventListener("once", event, extraData, method)
    }
}