// TODO: Repair detachEvent() and addEventListener() on all files
import * as Sentry from "@sentry/browser";

export const SENTRY = Sentry

Sentry.init({
    dsn: "https://1c5ce67c733c4f98b0d3cbb0a62d5aed@o4505275844919296.ingest.us.sentry.io/4505275848785920",
    tracePropagationTargets: ["localhost", /^https:\/\/web\.farmmedix\.com\/api/],
    integrations: self.document ?
        [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration()
        ]: undefined,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    beforeSend: async (event, hint) => {
        // Sentry.showReportDialog({
        //     eventId: event.event_id,
        //     labelClose: "Close"
        // })
        const error = hint.originalException as Error
        if (error && error.message && error.message.startsWith("Invalid PDF")) {
            event.fingerprint = ["invalid-pdf"]
        }

        return event
    },

    // Performance Monitoring
    tracesSampleRate: .5, // Capture 100% of the transactions, reduce in production!
    environment: self.location.host === "web.farmmedix.com" ? "production" : self.location.host,
    // Session Replay
    replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,
})
