import {ManagerBaseClass} from "../../services/ManagerBaseClass";
import {Database} from "../../services/DBClass";
import {tokenManager} from "../../services/tokenManager";
import {ArchiveManager} from "../../services/archiveManager";
import {SENTRY} from "../../SENTRY";
import {ArchiveData, StorageStatus} from "../../../models/DatabaseDataTypes";
import {DatabaseBatchOperation} from "../../databaseBatchOperation";
import {APIResponse} from "../../../models/APIResponse";
import {TABLES} from "../../../models/TABLES";
import {BasicIDBEvent, GlobalEventsService} from "../../services/events/globalEventsService";

export interface DBImageObject {
    uuid: string,
    data: Blob,
    hasBeenUploaded: boolean,
    fieldId: string,
    delete: boolean | undefined
}

export class DBImage extends ManagerBaseClass {
    readonly eventHandler = (e: BasicIDBEvent<ArchiveData> & {event: string}) => {
        if (
            // @ts-ignore
            e.details[0].table === TABLES.archive &&
            (e.details[0].data?.uuid || e.details[0].id) === this.data.uuid
        ) {
            switch (e.event) {
                case "onIndexedDBDeleted": return this.onDeleted()
                case "onIndexedDBModified": {
                    if (!e.details[0].data) return this.onDeleted();
                    return this.onModified(e.details[0].data)
                }
            }
        }
    }

    get [Symbol.toStringTag]() {return this.constructor.name}
    data: ArchiveData

    constructor(imageData: ArchiveData) {
        super()
        this.data = imageData
        ArchiveManager.addEventListener("archive_id_changed", (e) => {
            // @ts-ignore
            if (e.detail.old == this.imageId) this.imageId = e.detail.new
        })
    }

    load() {
        GlobalEventsService.on("onIndexedDBModified", this.eventHandler, null)
        GlobalEventsService.on("onIndexedDBDeleted", this.eventHandler, null)
    }

    unload() {
        GlobalEventsService.removeEventListener("onIndexedDBModified", this.eventHandler)
        GlobalEventsService.removeEventListener("onIndexedDBDeleted", this.eventHandler)
    }

    get() {
        return (Database.get(TABLES.archive, this.data.uuid)
            .then(e => {
                return e
            }))
    }

    async upload(sample_class: string, batch?: DatabaseBatchOperation) {
        let auto_commit_batch = false
        if (!batch) {
            batch = Database.batchOperation()
            auto_commit_batch = true
        }

        let headers = new Headers()
        headers.set("Content-Type", "application/json")
        headers.set("Authorization", "Bearer " + await tokenManager.getToken())

        try {
            if (this.data.storageStatus === StorageStatus.CLIENT_ONLY) {
                // Create the record
                const upload_start_req = await fetch("/api/v2/snapshot/reports/" + this.data.reportId + "/pictures", {
                    method: "POST",
                    headers,
                    body: JSON.stringify({
                        fieldData: {
                            "SampleClass": sample_class
                        }
                    })
                })

                // Fetch the record
                let data: APIResponse<{ ArchiveID: string }> = await upload_start_req.json()
                let img_data = await this.get()

                // Upload the image
                let form_data = new FormData()
                form_data.set("upload", new File([img_data.data], "upload.jpg"))

                const upload_req = await fetch("/api/v2/archive/items/" + data.response.ArchiveID + "/upload", {
                    method: "post",
                    headers: {"Authorization": "Bearer " + await tokenManager.getToken()},
                    body: form_data
                })
                const upload_req_res: APIResponse<{}> = await upload_req.json()
                if (upload_req_res.code !== 0) {
                    batch.put(TABLES.archive, img_data) // Save image data incase any changes were made
                    throw new Error("Server threw an error when uploading an image")
                }

                batch.delete(TABLES.archive, img_data.uuid) // Delete the original image from the local DB
                img_data.uuid = data.response.ArchiveID
                img_data.storageStatus = StorageStatus.SERVER_AND_CLIENT
                batch.put(TABLES.archive, img_data)
            } else {
                return
                // let data = new FormData()
                //
                // let xhttp = new XMLHttpRequest()
                // xhttp.onreadystatechange = () => {
                //     if (xhttp.readyState === 4 && xhttp.status === 200) {
                //
                //     }
                // }
                //
                // xhttp.open("post", "/fmi/data/v2/databases/Snapshot_2/layouts/")
                // xhttp.send()
            }
            if (auto_commit_batch) await batch.commit()
        } catch (e) {
            SENTRY.captureException(e)
            throw e
        }
    }

    async delete(batch?: DatabaseBatchOperation) {
        let data = await this.get()

        if (data.storageStatus === StorageStatus.SERVER_AND_CLIENT) {
            data.delete = true

            if (batch) {
                batch.put(TABLES.archive, data, (err) => {})
            } else {
                await Database.put(TABLES.archive, data)
            }
        } else {
            if (batch) {
                batch.delete(TABLES.archive, this.data.uuid, (err) => {})
            } else {
                await Database.delete(TABLES.archive, this.data.uuid)
            }

        }
    }

    save(): void
    save(batch: DatabaseBatchOperation): void
    async save(batch?: DatabaseBatchOperation) {
        let auto_commit = !batch
        if (!batch) batch = Database.batchOperation()
        batch.put(TABLES.archive, this.data)
        if (auto_commit) return batch.commit()
    }

    onModified(data: ArchiveData) {
        this.data = data
    }
    onDeleted() {}
}