// import {LRUCache} from "lru-cache";

export function makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

export function wait(milliseconds: number) {
    return new Promise<void>(resolve => {
        setTimeout(() => {
            resolve()
        }, milliseconds)
    })
}