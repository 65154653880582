import {
    AccountData,
    ArchiveData,
    FarmData,
    FieldData,
    HeaderData,
    SectionData,
    SupplierData,
    TestData
} from "./DatabaseDataTypes";

export enum TABLES {
    account = "account",
    tests = "tests",
    sections = "sections",
    headers = "headers",
    fields = "fields",
    farms = "farms",
    suppliers = "suppliers",
    archive = "archive"
}

export interface TableStructure {
    [TABLES.account]: AccountData,
    [TABLES.tests]: TestData,
    [TABLES.sections]: SectionData,
    [TABLES.headers]: HeaderData,
    [TABLES.fields]: FieldData,
    [TABLES.farms]: FarmData,
    [TABLES.suppliers]: SupplierData,
    [TABLES.archive]: ArchiveData
}