import {ManagerBaseClass} from "./ManagerBaseClass";

export class ArchiveManagerClass extends ManagerBaseClass {
    get [Symbol.toStringTag]() {return this.constructor.name}
    constructor() {
        super();
    }
}
export const ArchiveManager = new ArchiveManagerClass()

export interface ArchiveData {
    id: string,
    name: string,
}