import {BaseUpgradeKit, TableOptions} from "./baseUpgradeKit";
import {TABLES} from "../../../models/TABLES";

export class CreateNewDatabase implements BaseUpgradeKit {
    readonly oldVersion = 0
    readonly newVersion = 15
    readonly tables: TableOptions[] = [
        [TABLES.account, {keyPath: "id"}],
        [TABLES.tests, {keyPath: "uuid"}],
        [TABLES.sections, {keyPath: "uuid"}],
        [TABLES.headers, {keyPath: "uuid"}],
        [TABLES.fields, {keyPath: "uuid"}],
        [TABLES.farms, {keyPath: "uuid"}],
        [TABLES.suppliers, {keyPath: "uuid"}],
        [TABLES.archive, {keyPath: "uuid"}]
    ];
    readonly indexes = [
        {table: TABLES.account, name: "id_index", keyPath: "id", options: {unique: true}},
        {table: TABLES.tests, name: "uuid_index", keyPath: "uuid", options: {unique: false}},
        {table: TABLES.tests, name: "farm_id", keyPath: "farm_id", options: {unique: false}},
        {table: TABLES.tests, name: "storage_status", keyPath: "storageStatus", options: {unique: false}},
        {table: TABLES.sections, name: "uuid_index", keyPath: "uuid", options: {unique: true}},
        {table: TABLES.sections, name: "reportId", keyPath: "reportId", options: {unique: false}},
        {table: TABLES.headers, name: "uuid_index", keyPath: "id", options: {unique: true}},
        {table: TABLES.headers, name: "sectionId", keyPath: "sectionId", options: {unique: false}},
        {table: TABLES.headers, name: "reportId", keyPath: "reportId", options: {unique: false}},
        {table: TABLES.fields, name: "uuid_index", keyPath: "uuid", options: {unique: true}},
        {table: TABLES.fields, name: "headerId", keyPath: "headerId", options: {unique: false}},
        {table: TABLES.fields, name: "reportId", keyPath: "reportId", options: {unique: false}},
        {table: TABLES.farms, name: "uuid_index", keyPath: "uuid", options: {unique: true}},
        {table: TABLES.farms, name: "supplierId2", keyPath: "milkSuppliedTo", options: {unique: false}},
        {table: TABLES.farms, name: "storage_status", keyPath: "storageStatus", options: {unique: false}},
        {table: TABLES.suppliers, name: "uuid_index", keyPath: "uuid", options: {unique: true}},
        {table: TABLES.archive, name: "uuid_index", keyPath: "uuid", options: {unique: true}},
        {table: TABLES.archive, name: "reportId", keyPath: "reportId", options: {unique: false}},
        {table: TABLES.archive, name: "farmId", keyPath: "farmId", options: {unique: false}},
        {table: TABLES.archive, name: "storage_status", keyPath: "storageStatus", options: {unique: false}},
    ];

    performDataTransformation(transaction: IDBTransaction): void {}
}